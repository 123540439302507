import { CSSProperties, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp, FiFile } from 'react-icons/fi';
import { TbBrandPython, TbDatabase } from 'react-icons/tb';
import { VscFolder, VscFolderOpened, VscJson, VscMarkdown } from 'react-icons/vsc';
import { Box, Flex } from '@chakra-ui/react';
import Tree from 'rc-tree';
import { DataNode, Key } from 'rc-tree/lib/interface';
import shallow from 'zustand/shallow';

import { Label } from 'components/Typography';
import { FileContext } from 'contexts/FileContext';
import useMovefile from 'hooks/api/useMoveFile';
import { FileOrFolder } from 'hooks/useFileSystem';
import useStore, { GraphState } from 'views/Graph/state';
import 'rc-tree/assets/index.css';

import FileNode from './FileNode';
import FileNodeAlertDialog from './FileNodeAlertDialog';
import FileNodeEdit from './FileNodeEdit';
import { getChildFiles, getPathPrefix } from './utils';

export const treeStyles = {
  '.rc-tree': {
    width: '100%',
  },
  '.rc-tree .rc-tree-treenode span.rc-tree-switcher': {
    backgroundImage: 'none',
  },
  '& .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper': {
    width: '100%',
  },
  '& .rc-tree-treenode:hover': {
    backgroundColor: 'bg2',
  },
  '& .rc-tree-node-selected': {
    backgroundColor: 'activeMenu',
    boxShadow: 'none',
  },
  '.rc-tree-title': {
    width: 'calc(100% - 30px)',
  },
};

type IconWrapperProps = {
  children: ReactNode;
};

export const IconWrapper = ({ children }: IconWrapperProps) => {
  return (
    <Flex justifyContent="center" alignItems="center">
      {children}
    </Flex>
  );
};

function getFileIcon(filePath: string) {
  const fileParts = filePath.split('.');
  const fileExtension = fileParts[fileParts.length - 1];
  let FileIcon;

  switch (fileExtension) {
    case 'py':
      FileIcon = TbBrandPython;
      break;
    case 'md':
      FileIcon = VscMarkdown;
      break;
    case 'json':
      FileIcon = VscJson;
      break;
    case 'sql':
      FileIcon = TbDatabase;
      break;
    default:
      FileIcon = FiFile;
      break;
  }

  return (
    <IconWrapper>
      <FileIcon />
    </IconWrapper>
  );
}

const getFolderIcon = (expanded: boolean) => {
  return <IconWrapper>{expanded ? <VscFolderOpened /> : <VscFolder />}</IconWrapper>;
};

const selector = (s: GraphState) => ({
  openTab: s.openTab,
});

const noCursorStyle: CSSProperties = { cursor: 'default', pointerEvents: 'none' };

export function buildTree(
  files: FileOrFolder[],
  expandedKeys: string[],
  hoveredNodeId?: string,
  isPreview?: boolean
): DataNode[] {
  function createTreeNode(file: FileOrFolder): DataNode {
    return {
      key: file.id,
      title: file.editMode ? (
        <FileNodeEdit file={file} />
      ) : (
        <FileNode file={file} hovered={hoveredNodeId === file.path} isPreview={isPreview} />
      ),
      disabled: file.readOnly,
      icon:
        file.type === 'file'
          ? getFileIcon(file.fileName)
          : getFolderIcon(expandedKeys.includes(file.path)),
      style: file.readOnly ? noCursorStyle : {},
      isLeaf: file.type === 'file',
      children:
        file.type === 'folder'
          ? getChildFiles(file.path, files).map((childFile) => createTreeNode(childFile))
          : [],
    };
  }

  const treeNodes: DataNode[] = files
    .filter(({ fileName }) => fileName !== '.basis' && fileName !== 'metadata.json')
    .filter((file) => file.path.split('/').length === 1)
    .map((file) => createTreeNode(file));

  return treeNodes;
}

function FilesView() {
  const [hoveredNodeId, setHoveredNodeId] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>();
  const { fileState, filesDispatcher } = useContext(FileContext);
  const { mutate: moveFileMutate } = useMovefile();

  const selectedKeys = useMemo(() => {
    return fileState?.selectedFile ? [fileState.selectedFile] : [];
  }, [fileState?.selectedFile]);

  const { openTab } = useStore(selector, shallow);

  const openNode = useCallback(
    (file: FileOrFolder) => {
      if (file?.nodeId) {
        openTab(file.nodeId);
      } else if (file?.path) {
        openTab(file.path);
      }
    },
    [openTab]
  );

  const handleClick = useCallback(() => {
    if (!fileState?.selectedFile) return;
    const selectedFile = fileState?.files[fileState.selectedFile];
    openNode(selectedFile);
  }, [fileState?.files, fileState?.selectedFile, openNode]);

  const handleSelect = useCallback(
    (keys: Key[]) => {
      if (keys.length > 0 && keys.length === 1) {
        const file = fileState && fileState.files[keys[0].toString()];
        if (fileState) {
          filesDispatcher({
            type: 'SET',
            payload: {
              ...fileState,
              selectedFile: file?.id,
            },
          });
        }
        if (file) {
          openNode(file);
        }
      }
    },
    [fileState, filesDispatcher, openNode]
  );

  const treeData = useMemo(() => {
    if (fileState) {
      const sortedFiles = Object.keys(fileState.files)
        .sort()
        .map((key) => fileState.files[key]);
      return buildTree(sortedFiles, fileState.expandedFolders, hoveredNodeId, false);
    } else {
      return null;
    }
  }, [fileState, hoveredNodeId]);

  if (!fileState?.files || Object.keys(fileState?.files)?.length === 0) {
    return <Label px={2}>no data available</Label>;
  }

  return (
    <Box sx={treeStyles} minHeight="100%">
      {treeData && (
        <Tree
          treeData={treeData}
          draggable={true}
          onSelect={handleSelect}
          selectedKeys={selectedKeys}
          onMouseEnter={(event) => setHoveredNodeId(event.node.key as string)}
          onDrop={(info) => {
            const { dragNodesKeys, node, dropPosition } = info;
            if (!fileState) return;
            const [dragNodeKey] = dragNodesKeys;
            const dropFile = fileState.files[node.key.toString()];
            const dragFile = fileState.files[dragNodeKey.toString()];
            const pathPrefix =
              dropFile.type === 'file' ? getPathPrefix(dropFile.path) : `${dropFile.path}/`;
            const newPath =
              pathPrefix.length > 0 && dropPosition > -1
                ? `${pathPrefix}${dragFile.fileName}`
                : dragFile.fileName;
            if (newPath === dragFile.path) return;
            if (fileState.files.hasOwnProperty(newPath)) {
              setAlertMessage(`${newPath} already exists.`);
            } else {
              moveFileMutate({
                source: dragFile.path,
                destination: newPath,
              });
              filesDispatcher({
                type: 'SET',
                payload: {
                  ...fileState,
                  selectedFile: newPath,
                },
              });
            }
          }}
          switcherIcon={(props) => {
            if (props.isLeaf) return <></>;
            return props.expanded ? (
              <IconWrapper>
                <FiChevronDown />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <FiChevronUp />
              </IconWrapper>
            );
          }}
          expandedKeys={fileState?.expandedFolders}
          onExpand={(keys) => {
            if (!fileState) return;
            filesDispatcher({
              type: 'SET',
              payload: {
                ...fileState,
                expandedFolders: keys.map((key) => key.toString()),
              },
            });
          }}
          onClick={handleClick}
        />
      )}
      {alertMessage && (
        <FileNodeAlertDialog message={alertMessage} onClose={() => setAlertMessage('')} />
      )}
    </Box>
  );
}

export default FilesView;
