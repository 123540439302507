import { MouseEvent, MouseEventHandler } from 'react';
import { IconType } from 'react-icons';
import { Case, Default, Switch } from 'react-if';
import { Box, Center, Flex, Kbd, MenuItem, useColorMode } from '@chakra-ui/react';
import Image from 'next/image';
import { colors } from 'styles/colors';

import DarkTextWrapper from 'views/Graph/modules/GraphView/NodeTypes/components/DarkTextWrapper';

function MenuItemUI({
  onClick,
  name,
  label,
  Icon,
  iconSrc,
  shortcut,
  disableDefaultClick = false,
}: {
  onClick: MouseEventHandler;
  name: string;
  label?: string;
  Icon?: IconType;
  iconSrc?: string;
  shortcut?: string;
  disableDefaultClick?: boolean;
}) {
  const { colorMode } = useColorMode();

  return (
    <MenuItem onClick={onClick} key={name} fontWeight="500" pb={1} pt={1} px={2}>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flex={1}
        onClick={(e: MouseEvent) => {
          if (disableDefaultClick) {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
          }
        }}
      >
        <Flex justifyContent="flex-start" alignItems="center">
          <Center minWidth={'30px'}>
            {Icon && (
              <Switch>
                <Case condition={['Python', 'SQL'].includes(name)}>
                  <DarkTextWrapper isIcon={true}>{name[0].toUpperCase()}</DarkTextWrapper>
                </Case>
                <Default>{<Icon height={15} stroke={colors[colorMode].text1} />}</Default>
              </Switch>
            )}
            {iconSrc && <Image src={iconSrc} height={15} width={15} alt="icon" />}
          </Center>
          <Box mr={2}>{label || name}</Box>
        </Flex>
        {shortcut && <Kbd>{shortcut.toUpperCase()}</Kbd>}
      </Flex>
    </MenuItem>
  );
}

export default MenuItemUI;
