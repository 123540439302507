import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useDeleteFileURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

type DeleteFileData = {
  filePath: string | null;
};

function useDeleteFile(options?: UseMutationOptions<Graph, unknown, DeleteFileData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const url = useDeleteFileURL(graphVersionUID);

  const result = useGraphEditMutate<Graph, unknown, DeleteFileData>(
    async ({ filePath }: DeleteFileData) => {
      const res = await Axios.post<Graph>(url, {
        file_path: filePath,
      });
      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }
      return res?.data;
    },
    options
  );

  return result;
}

export default useDeleteFile;
