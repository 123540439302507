import { Button } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import useStore from 'state';
import { AppState } from 'types';
import { MarketplaceAppTemplate } from 'types/api';

type CreateGraphButtonProps = {
  label?: string;
  template: MarketplaceAppTemplate;
  isPreview?: boolean;
  onClick?: Function;
};

const selector = (state: AppState) => ({
  organizationUID: state.organizationUID,
  setShowCreateAppModal: state.setShowCreateAppModal,
});

function CloneTemplateButton({
  label = 'Create App',
  template,
  isPreview,
  onClick,
}: CreateGraphButtonProps) {
  const { organizationUID, setShowCreateAppModal } = useStore(selector, shallow);

  return (
    <>
      <Button
        onClick={() => {
          setShowCreateAppModal({
            orgUID: organizationUID!,
            graphUID: template.graph_uid,
            graphVersionUID: null,
            title: template.title,
            description: template.description,
            theme: 'default',
          });
          !!onClick && onClick();
        }}
        data-testid="create-graph-button"
        size="sm"
        fontSize="12px"
        fontWeight={500}
        height={isPreview ? '40px' : undefined}
      >
        {label}
      </Button>
    </>
  );
}

export default CloneTemplateButton;
