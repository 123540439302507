import { useMemo } from 'react';

import useReadme from './useReadme';

const shortDescriptionRegex = /\s*(?:#+[^\n]+\n *\n)?(.+?(?:\n *\n|$))/;

function useShortDescription() {
  const readme = useReadme();

  return useMemo(() => {
    const match = readme?.match(shortDescriptionRegex);
    return match && match.length > 1 ? match[1].trim() : undefined;
  }, [readme]);
}

export default useShortDescription;

function useShortDescriptionCustom(description?: string | null) {
  return useMemo(() => {
    const match = description?.match(shortDescriptionRegex);
    return match && match.length > 1 ? match[1].trim() : undefined;
  }, [description]);
}
export { useShortDescriptionCustom };
