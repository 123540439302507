import { FiGrid } from 'react-icons/fi';

import { GraphTemplate } from 'types/index';
import { getIconForNodeType } from 'utils/nodes';

export enum TemplateType {
  PYTHON = '.py',
  SQL = '.sql',
  CHART = '.json',
  WEBHOOK = 'webhook',
  TABLE_STORE = 'table',
  STREAM_STORE = 'stream',
  MARKDOWN = '.md',
  MARKETPLACE = 'marketplace',
}

export const simplePythonTemplate: GraphTemplate = {
  templateType: TemplateType.PYTHON,
  name: 'Python',
  nodeNamePrefix: 'python',
  description: '',
  icon: getIconForNodeType({ type: 'python' }),
  icon_url: '',
  shortcut: 'p',
  fileContent: `from patterns import (
    Connection,
    Parameter,
    State,
    Table,
)

# An input (readable) Table
# messages = Table("messages")

# An output (writeable) Table
# messages_with_metadata = Table("messages_with_metadata", "w")

# Parameters can be used for configuring your script or storing sensitive information
# processed_by = Parameter("user", default="system")

# Use a stream to process each record incrementally just once
# stream = messages.as_stream(order_by="timestamp")

# for record in stream.consume_records():
#     record["processed_at"] = now()
#     record["processed_by"] = processed_by
#     record["random"] = random.randint(0,100)
#     messages_with_metadata.append(record)
`,
};

export const sqlTemplate: GraphTemplate = {
  templateType: TemplateType.SQL,
  name: 'SQL',
  nodeNamePrefix: 'sql',
  description: '',
  icon: getIconForNodeType({ type: 'sql' }),
  shortcut: 'q',
  fileContent: `select
-- from {{ Table("my_table") }}
`,
};

export const chartTemplate: GraphTemplate = {
  templateType: TemplateType.CHART,
  name: 'Chart',
  nodeNamePrefix: 'chart',
  description: '',
  icon: getIconForNodeType({ type: 'chart' }),
  icon_url: '',
  shortcut: 'c',
  fileContent: ``,
};

export const webhookStandardTemplate: GraphTemplate = {
  templateType: TemplateType.WEBHOOK,
  name: 'Webhook',
  nodeNamePrefix: 'webhook',
  description: '',
  icon: getIconForNodeType({ type: 'webhook' }),
  shortcut: 'w',
  fileContent: '{}',
  wait_for_response: false,
};

export const webhookResponseTemplate: GraphTemplate = {
  templateType: TemplateType.WEBHOOK,
  name: 'Webhook',
  nodeNamePrefix: 'webhook',
  description: '',
  icon: getIconForNodeType({ type: 'webhook' }),
  shortcut: 'w',
  fileContent: '{}',
  wait_for_response: true,
};

export const tableStoreTemplate: GraphTemplate = {
  templateType: TemplateType.TABLE_STORE,
  name: 'Table',
  nodeNamePrefix: 'table',
  description: '',
  icon: getIconForNodeType({ type: 'table_store' }),
  icon_url: '',
  shortcut: 't',
  fileContent: '{}',
};

export const streamStoreTemplate: GraphTemplate = {
  templateType: TemplateType.STREAM_STORE,
  name: 'Stream',
  nodeNamePrefix: 'stream',
  description: '',
  icon: getIconForNodeType({ type: 'stream_store' }),
  icon_url: '',
  shortcut: 's',
  fileContent: '{}',
};

export const markdownTemplate: GraphTemplate = {
  templateType: TemplateType.MARKDOWN,
  name: 'Markdown',
  nodeNamePrefix: 'markdown',
  description: '',
  icon: getIconForNodeType({ type: 'markdown' }),
  icon_url: '',
  shortcut: 'd',
  fileContent: '# Markdown',
};

export const marketplaceTemplate: GraphTemplate = {
  templateType: TemplateType.MARKETPLACE,
  name: 'Explore Marketplace',
  nodeNamePrefix: 'Marketplace',
  description: '',
  icon: FiGrid,
  icon_url: '',
  shortcut: 'm',
  fileContent: '',
};

export const templates = [
  simplePythonTemplate,
  sqlTemplate,
  chartTemplate,
  webhookStandardTemplate,
  webhookResponseTemplate,
  tableStoreTemplate,
  streamStoreTemplate,
  markdownTemplate,
  marketplaceTemplate,
];
