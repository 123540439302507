import React, { FC, memo, SVGAttributes, useEffect, useRef, useState } from 'react';
import { ReactFlowState, useStore } from 'reactflow';

import { createGridDotsPath, createGridLinesPath } from './utils';

export enum BgVariant {
  Lines = 'lines',
  Dots = 'dots',
  Cross = 'cross',
}

const defaultColors = {
  [BgVariant.Dots]: '#81818a',
  [BgVariant.Lines]: '#eee',
  [BgVariant.Cross]: '#eee',
};

const transformSelector = (s: ReactFlowState) => s.transform;

type BackgroundProps = SVGAttributes<SVGElement> & {
  variant?: BgVariant;
  gap?: number;
  color?: string;
  size?: number;
  strokeWidth?: number;
};

const Background: FC<BackgroundProps> = ({
  variant = BgVariant.Dots,
  gap = 15,
  size = 4,
  strokeWidth = 0.4,
  color,
  style,
}) => {
  const ref = useRef<SVGSVGElement>(null);
  const [patternId, setPatternId] = useState<string | null>(null);
  const [tX, tY, tScale] = useStore(transformSelector);

  useEffect(() => {
    // when there are multiple flows on a page we need to make sure that every background gets its own pattern.
    const bgs = document.querySelectorAll('.react-flow__background');
    const index = Array.from(bgs).findIndex((bg) => bg === ref.current);
    setPatternId(`pattern-${index}`);
  }, []);

  const scaledGap = gap * tScale || 1;
  const xOffset = tX % scaledGap;
  const yOffset = tY % scaledGap;

  const isLines = variant === BgVariant.Lines;
  const isCross = variant === BgVariant.Cross;
  const isDots = variant === BgVariant.Dots;
  const bgColor = color ? color : defaultColors[variant];
  const linesSize = isCross ? size * tScale : scaledGap;

  const path =
    isLines || isCross
      ? createGridLinesPath(linesSize, +strokeWidth, bgColor)
      : createGridDotsPath(size * tScale, scaledGap, bgColor);

  let patternProps = {};

  if (isCross) {
    patternProps = { patternTransform: `translate(-${linesSize / 2}, -${linesSize / 2})` };
  } else if (isDots) {
    patternProps = { patternTransform: `translate(-${scaledGap / 2}, -${scaledGap / 2})` };
  }

  return (
    <svg
      className="react-flow__background react-flow__container"
      style={{
        ...style,
        width: '100%',
        height: '100%',
      }}
      ref={ref}
    >
      {patternId && (
        <>
          <pattern
            id={patternId}
            x={xOffset}
            y={yOffset}
            width={scaledGap}
            height={scaledGap}
            patternUnits="userSpaceOnUse"
            {...patternProps}
          >
            {path}
          </pattern>
          <rect x="0" y="0" width="100%" height="100%" fill={`url(#${patternId})`} />
        </>
      )}
    </svg>
  );
};

Background.displayName = 'Background';

export default memo(Background);
