import { useCallback } from 'react';
import { XYPosition } from 'reactflow';

import useAddNodeFile from 'hooks/api/useAddNodeFile';
import useAddStoreNode from 'hooks/api/useAddStoreNode';
import useAddWebhook from 'hooks/api/useAddWebhook';
import { GraphTemplate } from 'types';
import { TemplateType } from 'utils/templates';

type AddNodeParams = {
  position: XYPosition;
  graphLevel: string;
  item: GraphTemplate;
};

export function makeNodeType(templateType: string): 'python' | 'sql' | 'markdown' | 'chart' {
  if (templateType === TemplateType.PYTHON) {
    return 'python';
  } else if (templateType === TemplateType.SQL) {
    return 'sql';
  } else if (templateType === TemplateType.MARKDOWN) {
    return 'markdown';
  } else if (templateType === TemplateType.CHART) {
    return 'chart';
  }
  throw new Error(`Invalid templateType: ${templateType}`);
}

function useAddNode() {
  const { mutate: addNodeFile, isGraphSaving: isGraphSaving1 } = useAddNodeFile();
  const { mutate: addWebhook, isGraphSaving: isGraphSaving2 } = useAddWebhook();
  const { mutate: addStoreNode, isGraphSaving: isGraphSaving4 } = useAddStoreNode();

  const addNode = useCallback(
    async ({ position, graphLevel, item }: AddNodeParams) => {
      const parentId = graphLevel === 'root' ? null : graphLevel;

      if (item.templateType === TemplateType.WEBHOOK) {
        addWebhook({
          parent_id: parentId || null,
          position_x: position.x,
          position_y: position.y,
          webhook: item.nodeNamePrefix,
          icon_url: null,
          description: item.description,
          wait_for_response: item.wait_for_response,
        });
      } else if (
        item.templateType === TemplateType.TABLE_STORE ||
        item.templateType === TemplateType.STREAM_STORE
      ) {
        addStoreNode({
          templateType: item.templateType,
          parentId,
          position,
        });
      } else {
        addNodeFile({
          description: item.description,
          iconUrl: item.icon_url,
          fileContents: item.fileContent,
          nodeType: makeNodeType(item.templateType),
          parentId: parentId,
          position,
          title: item.title,
          chart_input: item.chart_input,
          fileName: item.fileName,
        });
      }
    },
    [addNodeFile, addStoreNode, addWebhook]
  );

  return {
    addNode,
    isSaving: isGraphSaving1 || isGraphSaving2 || isGraphSaving4,
  };
}

export default useAddNode;
