import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Handle, Position, useStoreApi as useRFStore } from 'reactflow';
import { ColorMode, Flex, useColorMode } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import shallow from 'zustand/shallow';

import config from 'config';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { NodeStatus, PatternsNodeProps } from 'types';
import { NodeExecution, NodeType } from 'types/api';
import { isStoreNodeType, isSubgraphNodeType } from 'utils/nodes';
import { getColorById, getDimensionByNodeType } from 'views/Graph/modules/GraphView/utils';
import useStore from 'views/Graph/state';

import NodeForm from './components/NodeForm';
import NodeIcon from './components/NodeIcon';
import NodeMenu from './components/NodeMenu';
import NodeResizer from './components/NodeResizer';
import NodeShape from './components/NodeShape';
import NodeTitle from './components/NodeTitle';
import NodeVisualization from './components/NodeVisualization';
import PatternsHandle from './components/PatternsHandle';

export const getBgColor = ({
  color,
  isStoreNode,
  isDarkMode,
}: {
  color: string | null;
  isStoreNode: boolean;
  isDarkMode: boolean;
}) => {
  if (color) {
    return color;
  } else if (isDarkMode) {
    return isStoreNode ? '#555' : '#444';
  }

  return isStoreNode ? '#eee' : '#fefefe';
};

export const getIconColor = ({
  hasErrors,
  colorMode,
}: {
  hasErrors: boolean;
  colorMode: ColorMode;
}) => {
  if (hasErrors) {
    return 'red.600';
  }

  return colors[colorMode].text1;
};

export const getStrokeColor = ({
  selected,
  isDarkMode,
  willRun,
  isSuccess,
  isError,
  isRunning,
}: {
  selected: boolean;
  isDarkMode: boolean;
  willRun: boolean;
  isSuccess: boolean;
  isError: boolean;
  isRunning: boolean;
}) => {
  const key = isDarkMode ? 'dark' : 'light';
  if (willRun) return colors[key].nodeRunning;
  if (isRunning) return colors[key].nodeRunning;
  if (isError) return colors[key].nodeError;
  if (isSuccess) return colors[key].nodeSuccess;
  if (selected) return isDarkMode ? colors.gray[500] : colors.dark.bg3;

  return isDarkMode ? colors.gray[600] : colors.dark.bg3;
};

const isExecutionNode = (dataType: NodeType) => {
  return ['python', 'sql', 'graph', 'component_ref', 'webhook'].includes(dataType);
};

function PatternsNode({ id, data, selected, isConnectable }: PatternsNodeProps) {
  const {
    isHoverNode,
    isHoverMenuNode,
    willRun,
    isNodeRunning,
    isWebhookRunning,
    isNodeQueued,
    isNodeSuccess,
    isNodeError,
    node,
    setHoverNodeId,
    nodeChanges,
  } = useStore(
    useCallback(
      (state) => ({
        isHoverNode: state.hoverNodeId === id,
        isHoverMenuNode: state.hoverMenuNodeId === id,
        willRun: state.nodesWillRun.includes(id),
        isNodeRunning: state.runningNodeIds.includes(id),
        isWebhookRunning: state.runningWebhookIds.includes(id),
        isNodeQueued: state.queuedNodeIds.includes(id),
        isNodeSuccess: state.successNodeIds.includes(id),
        isNodeError: state.errorNodeIds.includes(id),
        node: state.nodes.find((node) => node.id === id),
        setHoverNodeId: state.setHoverNodeId,
        nodeChanges: state.nodeChanges[id],
      }),
      [id]
    ),
    shallow
  );

  const [showSuccess, setShowSuccess] = useState(false);
  const { readOnly } = useReadOnlyMode();

  useEffect(() => {
    function executionUpdates(
      nodeExecution?: NodeExecution | null,
      previousNodeExecution?: NodeExecution | null
    ): void {
      if (!nodeExecution || !previousNodeExecution) return;
      if (previousNodeExecution.completed === null && nodeExecution.completed !== null) {
        setShowSuccess(true);
      }
    }

    const executionsUnsubscriber = useStore.subscribe(
      (state) => state.GraphStatus?.executions[id],
      executionUpdates
    );
    return () => {
      executionsUnsubscriber();
    };
  }, [id]);

  useEffect(() => {
    function executionUpdates(webhookIds: string[], previousWebhookIds: string[]): void {
      if (!webhookIds.includes(id) && previousWebhookIds.includes(id)) {
        setShowSuccess(true);
      }
    }

    const executionsUnsubscriber = useStore.subscribe(
      (state) => state.runningWebhookIds,
      executionUpdates
    );
    return () => {
      executionsUnsubscriber();
    };
  }, [id]);

  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === 'dark';
  const isStoreNode = isStoreNodeType(data.type);
  const isChart = data.type === 'chart';
  const isWebhook = data.type === 'webhook';

  const nodeColor = getColorById(data?.display?.color || null, isDarkMode);

  const hasErrors = (data.manifestErrors?.length || 0) > 0;
  const bgColor = getBgColor({ color: nodeColor, isStoreNode, isDarkMode });

  const isRunning = useMemo((): boolean => {
    if (!isExecutionNode(data.type)) return false;
    return isNodeRunning || isWebhookRunning;
  }, [data.type, isNodeRunning, isWebhookRunning]);

  const isQueued = useMemo((): boolean => {
    if (!isExecutionNode(data.type)) return false;
    return isNodeQueued;
  }, [data.type, isNodeQueued]);

  const isSuccess = useMemo((): boolean => {
    if (willRun) return false;
    if (!isExecutionNode(data.type)) return false;
    return isNodeSuccess;
  }, [data.type, isNodeSuccess, willRun]);

  const isError = useMemo((): boolean => {
    if (willRun) return false;
    if (!isExecutionNode(data.type)) return false;
    return isNodeError;
  }, [data, isNodeError, willRun]);

  const isHovering = useMemo(() => isHoverNode || isHoverMenuNode, [isHoverMenuNode, isHoverNode]);

  const { getState } = useRFStore();

  // Handles a fringe case where the node is selected but the user is not hovering it
  const circleDiameterRef = useRef(8);

  const circleDiameter = useMemo(() => {
    // This prevents unnecessary renders when the user is not hovering the node
    if (!isHovering) return circleDiameterRef.current;
    // Scaling the size of the handles based on the zoom level
    const { transform } = getState();
    const scale = +(1 / transform[2]).toFixed(2);
    const clampedScale = Math.min(0.5 + scale, 2);
    const circleDiameter = 8 * clampedScale;
    circleDiameterRef.current = circleDiameter;
    return circleDiameter;
  }, [getState, isHovering]);

  const nodeStatus = useMemo((): NodeStatus => {
    if (willRun) return NodeStatus.WillRun;

    if (isRunning) return NodeStatus.Running;
    if (isQueued) return NodeStatus.Queued;
    if (isError) return NodeStatus.Error;
    if (isSuccess) return NodeStatus.Success;
    return NodeStatus.None;
  }, [isError, isQueued, isRunning, isSuccess, willRun]);

  const strokeColor = getStrokeColor({
    selected,
    isDarkMode,
    willRun,
    isSuccess,
    isError,
    isRunning,
  });

  const [isHoverNodeWithDelay, setIsHoverNodeWithDelay] = useState(false);
  // only used isHoverMenuNode (not isHoverNode) so menus only show when actually hovering over the node
  useEffect(() => {
    if (isHoverMenuNode) {
      const timer = setTimeout(() => {
        setIsHoverNodeWithDelay(true);
      }, 200);
      return () => clearTimeout(timer);
    } else {
      setIsHoverNodeWithDelay(false);
    }
  }, [isHoverMenuNode, setIsHoverNodeWithDelay]);

  const [tempWidth, setTempWidth] = useState<null | number>(null);
  const [tempHeight, setTempHeight] = useState<null | number>(null);

  const width = tempWidth || (nodeChanges?.width || data.display?.width || 1) * config.graphGrid.x;
  const height =
    tempHeight || (nodeChanges?.height || data.display?.height || 1) * config.graphGrid.y;

  const { width: offsetX, height: offsetY } = getDimensionByNodeType(data.type) as {
    width: number;
    height: number;
  };

  const isForm = useMemo(() => {
    return data.display?.style === 'form';
  }, [data.display?.style]);

  const shapeHeight = useMemo(() => {
    if (isChart) {
      return height - 20;
    } else {
      return undefined;
    }
  }, [height, isChart]);

  const shapeWidth = useMemo(() => {
    if (isChart) {
      return width - 20;
    } else {
      return undefined;
    }
  }, [isChart, width]);

  const handleOffset = useMemo(() => {
    if (isChart) {
      return circleDiameter / 2;
    } else if (isForm) {
      return 8 - circleDiameter / 2;
    } else {
      return config.graphGrid.x / 2 - offsetX / 2 - circleDiameter / 2;
    }
  }, [circleDiameter, isChart, isForm, offsetX]);

  const [hoverClass, setHoverClass] = useState('');

  useEffect(() => {
    const classPrefix = isForm ? 'node-form' : 'node-shape';
    if (isHovering) {
      setHoverClass(`${classPrefix}_foreground ${classPrefix}_hover`);
    } else {
      setHoverClass(`${classPrefix}_foreground`);
    }
  }, [isForm, isHovering, nodeStatus]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);

  return (
    <div
      className={(readOnly ? 'nodrag' : '') + ' patternsNode'}
      data-name={data.name?.toLocaleLowerCase().replaceAll(' ', '-')}
      style={{
        cursor: readOnly ? 'pointer' : 'initial',
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onMouseEnter={() => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
        setHoverNodeId(id, true);
      }}
      onMouseLeave={() => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          const { hoverNodeId } = useStore.getState();
          if (hoverNodeId === id) {
            setHoverNodeId(null);
          }
        }, 200);
      }}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          // ignore clicks that are in the node backgrounds
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <NodeResizer
        id={id}
        setTempWidth={setTempWidth}
        setTempHeight={setTempHeight}
        isVisible={!readOnly && (isForm || isChart) && selected}
      />

      {!readOnly && !isForm && isHoverNodeWithDelay && (
        <NodeMenu
          id={id}
          showEdit={false}
          showExecute={!isWebhook && !isStoreNode && !isChart}
          showDuplicate={!isSubgraphNodeType(data.type)}
          showResetStore={isStoreNode}
          showResetState={!isWebhook && !isStoreNode && !isChart}
          isChart={isChart}
          offset={45 - offsetY / 2}
        />
      )}

      {isForm && (
        <NodeForm
          id={id}
          strokeColor={strokeColor}
          data={data}
          width={width - 20}
          height={height - 20}
          hoverClass={hoverClass}
        />
      )}

      {!isForm && !isChart && (
        <NodeShape
          nodeId={id}
          nodeType={data.type}
          fill={bgColor}
          strokeColor={strokeColor}
          strokeWidth={selected ? 2 : 1}
          status={nodeStatus}
          showSuccessAnimation={showSuccess}
          setShowSuccessAnimation={setShowSuccess}
          height={shapeHeight}
          width={shapeWidth}
          hoverClass={hoverClass}
        />
      )}

      {!isChart && !isForm && (
        <NodeIcon
          nodeId={id}
          nodeData={data}
          color={getIconColor({ hasErrors, colorMode })}
          // 7.3 is used place the icon in the centroid of the triangle for webhooks
          offset={isWebhook ? '-7.3px' : '0px'}
        />
      )}

      {!isForm && (
        <NodeTitle id={id} nodeData={data} data-testid="node-title" offset={offsetY / 2} />
      )}

      {/* node is defined on the graph page but not the marketplace page */}
      {node ? (
        <>
          {!isWebhook && (
            <PatternsHandle
              node={node}
              side="left"
              isConnectable={isConnectable}
              offset={handleOffset}
              diameter={circleDiameter}
            />
          )}

          {!isChart && (
            <PatternsHandle
              node={node}
              side="right"
              isConnectable={isConnectable}
              offset={handleOffset}
              diameter={circleDiameter}
            />
          )}
        </>
      ) : (
        <Flex onClick={(e) => e.stopPropagation()}>
          <Handle
            type="target"
            style={{ left: handleOffset, opacity: 0 }}
            position={Position.Left}
          />
          <Handle
            type="source"
            style={{ left: 'auto', right: handleOffset, opacity: 0 }}
            position={Position.Right}
          />
        </Flex>
      )}

      {isChart && (
        <NodeVisualization
          id={id}
          strokeColor={strokeColor}
          data={data}
          width={width - 20}
          height={height - 20}
          graphUID={data?.graphUID}
        />
      )}
    </div>
  );
}

export default memo(PatternsNode);
