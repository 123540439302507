import { useMemo } from 'react';

import useMarketplaceAppsByCategory from './api/useMarketplaceAppsByCategory';

function useMarketplaceFeaturedApps() {
  const { data: appsByCategory, isLoading } = useMarketplaceAppsByCategory();

  const featuredApps = useMemo(() => {
    if (appsByCategory) {
      return appsByCategory.categories
        .filter((category) => category.title.toLowerCase().includes('featured'))
        .map((category) => category.templates)
        .flat()
        .sort((a, b) => ((a.index || 100) > (b.index || 100) ? 1 : -1))
        .reverse();
    } else {
      return [];
    }
  }, [appsByCategory]);

  return { isLoading, data: featuredApps };
}

export default useMarketplaceFeaturedApps;
