import React, { useState } from 'react';
import { NodeResizer, ResizeDragEvent, ResizeEventParams } from '@reactflow/node-resizer';

import config from 'config';
import useStore from 'views/Graph/state';

function NodeResizerCustom({
  id,
  setTempWidth,
  setTempHeight,
  isVisible,
}: {
  id: string;
  setTempWidth: (w: number | null) => void;
  setTempHeight: (h: number | null) => void;
  isVisible: boolean;
}) {
  const [resizeDisabled, setResizeDisabled] = useState(false);

  return (
    <NodeResizer
      color="action"
      minWidth={100}
      minHeight={100}
      isVisible={isVisible && !resizeDisabled}
      onResizeEnd={(event: ResizeDragEvent, params: ResizeEventParams) => {
        const { setNodeChanges, nodeChanges } = useStore.getState();
        setNodeChanges({
          ...nodeChanges,
          ...{
            [id]: {
              height: Math.round(params.height / config.graphGrid.x),
              width: Math.round(params.width / config.graphGrid.y),
              position_x: Math.round(params.x / config.graphGrid.x),
              position_y: Math.round(params.y / config.graphGrid.y),
            },
          },
        });
        // on resize end -> disable resizer for 1000ms to reset it
        setResizeDisabled(true);
        setTimeout(() => setResizeDisabled(false), 1000);
        setTempWidth(null);
        setTempHeight(null);
      }}
      onResize={(event: ResizeDragEvent, params: ResizeEventParams) => {
        setTempHeight(params.height);
        setTempWidth(params.width);
      }}
      lineStyle={{ zIndex: 5 }}
      handleStyle={{ width: '10px', height: '10px', borderRadius: '50%', zIndex: 5 }}
    />
  );
}

export default NodeResizerCustom;
