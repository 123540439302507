import React, { MouseEvent } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { ReactNode } from 'react-markdown/lib/react-markdown';
import { Box, chakra, Flex } from '@chakra-ui/react';

import PageWrapper from 'components/PageWrapper';
import EditorLayout from 'layouts/EditorLayout';

const ChevronLeft = chakra(FiChevronLeft);

type ItemPageLayoutProps = {
  heroContent: ReactNode;
  children: ReactNode;
  showHeader?: boolean;
  onBack?: (event: MouseEvent) => void;
};

function ItemPageLayout({ showHeader, heroContent, onBack, children }: ItemPageLayoutProps) {
  return (
    <EditorLayout
      height="100%"
      width="100%"
      showSidebar={false}
      showHeader={showHeader}
      heroContent={
        <Box bg="#FBFBFB" width="full" pb={5}>
          <PageWrapper>
            {onBack && (
              <Flex
                as="a"
                color="dark.bg1"
                fontWeight={600}
                alignItems="center"
                _hover={{ opacity: 0.8, color: 'action' }}
                onClick={(event) => onBack?.(event)}
                ml={{ base: -1, md: 6, lg: 10, xl: 12 }}
                zIndex={3}
              >
                <ChevronLeft mr={2} w={4} h={4} /> Back
              </Flex>
            )}
            <Box px={{ base: 0, md: 6, lg: 10, xl: 14 }} mt={2}>
              {heroContent}
            </Box>
          </PageWrapper>
        </Box>
      }
    >
      <PageWrapper>
        <Box px={{ base: 0, md: 6, lg: 10, xl: 14 }} pb={8} height="100%">
          {children}
        </Box>
      </PageWrapper>
    </EditorLayout>
  );
}

export default ItemPageLayout;
