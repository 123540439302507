import { useCallback } from 'react';

import useAddNodeFile from 'hooks/api/useAddNodeFile';
import useAddStoreNode from 'hooks/api/useAddStoreNode';
import useAddWebhook from 'hooks/api/useAddWebhook';
import useAssignPort from 'hooks/api/useAssignPort';
import useNodeModel from 'hooks/api/useNodeModel';
import useUpdateNodeFile from 'hooks/api/useUpdateNodeFile';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import useGetUniqueNodeName from 'hooks/useGetUniqueNodeName';
import { GraphNode, GraphTemplate } from 'types';
import { TemplateType } from 'utils/templates';
import { makeNodeType } from 'views/Graph/components/AddNodeMenu/useAddNode';

type useAddContextHandlerProps = {
  node: GraphNode;
  position: { x: number; y: number };
  side: 'left' | 'right';
};

function useAddContextHandler({ node, position, side }: useAddContextHandlerProps) {
  const nodeId = node.id;
  const nodeData = node.data;

  const { mutateAsync: addNodeFile } = useAddNodeFile();
  const { mutateAsync: addWebhookNode } = useAddWebhook();
  const { mutateAsync: addStoreNode } = useAddStoreNode();
  const { mutateAsync: assignPort } = useAssignPort();
  const { mutate: updateNodes } = useUpdateNodes();
  const { mutate: updateNodeFile } = useUpdateNodeFile();

  const {
    data: { code: currentCode, model },
  } = useNodeModel(nodeId, nodeData.filePath);

  const getUniqueNodeName = useGetUniqueNodeName();

  const addPythonToTable = useCallback(
    async (template: GraphTemplate) => {
      const portName = getUniqueNodeName(template.nodeNamePrefix);
      // commenting out for now until we decide what to do with explicit/implicit edges
      // const portName =
      //   (side === 'right' ? 'input_table' : 'output_table') + Math.floor(Math.random() * 1000);

      const appendText =
        node.data.type === 'stream_store'
          ? `${nodeData.name} = Stream("${portName}", "${side === 'left' ? 'w' : 'r'}")`
          : `${nodeData.name} = Table("${portName}", "${side === 'left' ? 'w' : 'r'}")`;
      const fileContent = template.fileContent + '\n' + appendText + '\n';

      const newGraph = await addNodeFile({
        fileContents: fileContent,
        iconUrl: template.icon_url,
        description: template.description,
        nodeType: makeNodeType(template.templateType),
        position,
        addOutputStores: side === 'right',
      });
      if (newGraph.added_node_id) {
        await assignPort({
          function_node_id: newGraph.added_node_id,
          store_node_id: nodeId,
          port_name: portName,
          direction: side === 'right' ? 'input' : 'output',
        });
      }
    },
    [
      addNodeFile,
      assignPort,
      getUniqueNodeName,
      node.data.type,
      nodeData.name,
      nodeId,
      position,
      side,
    ]
  );

  // commenting out for now until we decide what to do with explicit/implicit edges
  // const addSqlToTable = useCallback(
  //   async (template: GraphTemplate) => {
  //     let fileContent: string;
  //     let portName: string = '';
  //     if (side === 'left') {
  //       fileContent = template.fileContent;
  //     } else {
  //       portName = 'input_table' + Math.floor(Math.random() * 1000);
  //       fileContent = `select *\nfrom {{ Table("${portName}") }}`;
  //     }
  //
  //     const newGraph = await addNodeFile({
  //       fileContents: fileContent,
  //       iconUrl: template.icon_url,
  //       description: template.description,
  //       nodeType: makeNodeType(template.templateType),
  //       position,
  //       addOutputStores: side === 'right',
  //     });
  //
  //     if (newGraph.added_node_id) {
  //       await assignPort({
  //         function_node_id: newGraph.added_node_id,
  //         store_node_id: nodeId,
  //         port_name: side === 'left' ? 'output_table' : portName,
  //         direction: side === 'right' ? 'input' : 'output',
  //       });
  //     }
  //   },
  //   [addNodeFile, assignPort, nodeId, position, side]
  // );

  const addSqlToTable = useCallback(
    async (template: GraphTemplate) => {
      const fileContent =
        side === 'left' ? template.fileContent : `select *\nfrom {{ Table("${node.data.name}") }}`;

      const newGraph = await addNodeFile({
        fileContents: fileContent,
        iconUrl: template.icon_url,
        description: template.description,
        nodeType: makeNodeType(template.templateType),
        position,
        addOutputStores: side === 'right',
      });

      if (newGraph.added_node_id && side === 'left') {
        await assignPort({
          function_node_id: newGraph.added_node_id,
          store_node_id: nodeId,
          port_name: 'output_table',
          direction: 'output',
        });
      }
    },
    [addNodeFile, assignPort, node.data.name, nodeId, position, side]
  );

  const addChartToTable = useCallback(
    async (template: GraphTemplate) => {
      await addNodeFile({
        fileContents: template.fileContent,
        position,
        nodeType: 'chart',
        chart_input: node.data.name,
      });
    },
    [addNodeFile, node.data.name, position]
  );

  const addWebhookToTable = useCallback(
    async (template: GraphTemplate) => {
      const newGraph = await addWebhookNode({
        position_x: position.x,
        position_y: position.y,
        webhook: node.data.name,
        addOutputStores: false,
      });
      if (newGraph.added_node_id) {
        updateNodes({ [newGraph.added_node_id]: { webhook: node.data.name } });
      }
    },
    [addWebhookNode, node.data.name, position.x, position.y, updateNodes]
  );

  const addTableToPython = useCallback(
    async (template: GraphTemplate) => {
      const newNodeName = getUniqueNodeName(template.nodeNamePrefix);

      // commenting out for now until we decide what to do with explicit/implicit edges
      // const portName =
      //   (side === 'left' ? 'input_table' : 'output_table') + Math.floor(Math.random() * 1000);
      const portName = newNodeName;

      const appendText =
        template.templateType === TemplateType.STREAM_STORE
          ? `${newNodeName} = Stream("${portName}", "${side === 'left' ? 'r' : 'w'}")`
          : `${newNodeName} = Table("${portName}", "${side === 'left' ? 'r' : 'w'}")`;

      const fileContent = currentCode + '\n' + appendText + '\n';
      model?.setValue(fileContent);
      let newGraph = await addStoreNode({
        templateType: template.templateType,
        position: position,
        nodeId: nodeId,
        nodePort: newNodeName,
        uniqueNodeName: newNodeName,
        direction: side === 'left' ? 'input' : 'output',
      });
      if (newGraph.added_node_id) {
        newGraph = await assignPort({
          function_node_id: nodeId,
          store_node_id: newGraph.added_node_id,
          port_name: portName,
          direction: side === 'left' ? 'input' : 'output',
        });
      }

      updateNodeFile({
        graph_version_uid: newGraph.version_uid,
        filePath: nodeData.filePath!,
        fileName: nodeData.name,
        fileContent: fileContent,
        overrideCache: true,
      });
    },
    [
      addStoreNode,
      assignPort,
      currentCode,
      getUniqueNodeName,
      model,
      nodeData.filePath,
      nodeData.name,
      nodeId,
      position,
      side,
      updateNodeFile,
    ]
  );

  const addTableToSql = useCallback(
    async (template: GraphTemplate) => {
      if (side === 'left') {
        const uniqueNodeName = getUniqueNodeName(template.templateType);
        const portName = uniqueNodeName;
        // commenting out for now until we decide what to do with explicit/implicit edges
        // const portName =
        //   (side === 'left' ? 'input_table' : 'output_table') + Math.floor(Math.random() * 1000);

        let newGraph = await addStoreNode({
          templateType: template.templateType,
          position: position,
          uniqueNodeName: uniqueNodeName,
        });

        if (newGraph.added_node_id) {
          newGraph = await assignPort({
            function_node_id: nodeId,
            store_node_id: newGraph.added_node_id,
            port_name: portName,
            direction: side === 'left' ? 'input' : 'output',
          });
        }

        const appendText = `select *\nfrom {{ Table("${portName}") }}`;
        const fileContent = currentCode + '\n' + appendText + '\n';
        updateNodeFile({
          graph_version_uid: newGraph.version_uid,
          filePath: nodeData.filePath!,
          fileName: nodeData.name,
          fileContent: fileContent,
          overrideCache: true,
        });
      } else {
        addStoreNode({
          templateType: template.templateType,
          position: position,
          nodeId: nodeId,
          nodePort: 'output_table',
          direction: 'output',
        });
      }
    },
    [
      addStoreNode,
      assignPort,
      currentCode,
      getUniqueNodeName,
      nodeData.filePath,
      nodeData.name,
      nodeId,
      position,
      side,
      updateNodeFile,
    ]
  );

  const addTableToWebhook = useCallback(
    async (template: GraphTemplate) => {
      const newNodeName = getUniqueNodeName(template.nodeNamePrefix);
      const newGraph = await addStoreNode({
        templateType: template.templateType,
        position: position,
        nodePort: newNodeName,
      });
      if (newGraph.added_node_id) {
        updateNodes({ [nodeId]: { webhook: newNodeName } });
      }
    },
    [addStoreNode, getUniqueNodeName, nodeId, position, updateNodes]
  );

  const addTableToChart = useCallback(
    async (template: GraphTemplate) => {
      const newNodeName = getUniqueNodeName(template.nodeNamePrefix);
      const newGraph = await addStoreNode({
        templateType: template.templateType,
        position: position,
        nodePort: newNodeName,
      });
      if (newGraph.added_node_id) {
        updateNodes({ [nodeId]: { chart_input: newNodeName } });
      }
    },
    [addStoreNode, getUniqueNodeName, nodeId, position, updateNodes]
  );

  return {
    addChartToTable,
    addWebhookToTable,
    addTableToPython,
    addTableToSql,
    addTableToWebhook,
    addTableToChart,
    addPythonToTable,
    addSqlToTable,
  };
}

export default useAddContextHandler;
