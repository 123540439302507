import { ShapeProps } from './common';
import CustomPath from './CustomPath';

function Diamond({ width, height = 50, ...rest }: ShapeProps) {
  const borderRadius = 5;
  const xyOffset = Math.sqrt(borderRadius ** 2 / 2);

  // M = start, 0 0 => x y
  // L = line, 0 0 => x y
  // A = arc
  // Z = end
  const pathData = `
    M ${xyOffset} ${height / 2 + xyOffset}
    L ${width / 2 - xyOffset} ${height - xyOffset}
    A ${borderRadius} ${borderRadius} 0 0 0 ${width / 2 + xyOffset} ${height - xyOffset}
    L ${width - xyOffset} ${height / 2 + xyOffset}
    A ${borderRadius} ${borderRadius} 0 0 0 ${width - xyOffset} ${height / 2 - xyOffset}
    L ${width / 2 + xyOffset} ${xyOffset}
    A ${borderRadius} ${borderRadius} 0 0 0 ${width / 2 - xyOffset} ${xyOffset}
    L ${xyOffset} ${height / 2 - xyOffset}
    A ${borderRadius} ${borderRadius} 0 0 0 ${xyOffset} ${height / 2 + xyOffset}
    Z
    `;

  return <CustomPath {...{ pathData, ...rest }} />;
}

export default Diamond;
