import { Case, Switch } from 'react-if';
import { Box } from '@chakra-ui/react';

import { NodeStatus } from 'types';
import { NodeType } from 'types/api';
import { isComponentNodeType, isSubgraphNodeType } from 'utils/nodes';
import { getDimensionByNodeType } from 'views/Graph/modules/GraphView/utils';

import Diamond from './Diamond';
import Hexagon from './Hexagon';
import Rectangle from './Rectangle';
import Triangle from './Triangle';

const wrapperSx = {
  'svg': {
    overflow: 'visible',
  },
  'svg *': {
    transformOrigin: 'center center',
  },
};

type ShapeWrapperProps = {
  nodeId: string;
  fill: string;
  nodeType: NodeType;
  strokeColor: string;
  status: NodeStatus;
  strokeWidth: number;
  showSuccessAnimation?: boolean;
  setShowSuccessAnimation?: (show: boolean) => void;
  position?: 'relative' | 'absolute';
  height?: number;
  width?: number;
  hoverClass?: string;
};

function Shape({
  nodeId,
  nodeType,
  fill,
  strokeColor,
  status,
  strokeWidth,
  showSuccessAnimation,
  setShowSuccessAnimation,
  position = 'absolute',
  height: customHeight,
  width: customWidth,
  hoverClass,
}: ShapeWrapperProps) {
  const isFunctionNode = nodeType === 'sql' || nodeType === 'python';
  const isDataNode = nodeType === 'table_store' || nodeType === 'stream_store';
  const isSubNode = isComponentNodeType(nodeType) || isSubgraphNodeType(nodeType);
  const isWebhook = nodeType === 'webhook';
  const isChart = nodeType === 'chart';
  const { width: defaultWidth, height: defaultHeight } = getDimensionByNodeType(nodeType) as {
    width: number;
    height: number;
  };

  const width = customWidth || defaultWidth;
  const height = customHeight || defaultHeight;

  return (
    <Box position={position} zIndex={2} sx={wrapperSx} p={'8px'}>
      <svg width={width} height={height} data-shapeid={nodeId}>
        <Switch>
          <Case condition={isDataNode || isChart}>
            <Rectangle
              width={width}
              height={height}
              fill={fill}
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              status={status}
              showSuccessAnimation={showSuccessAnimation}
              setShowSuccessAnimation={setShowSuccessAnimation}
              hoverClass={hoverClass}
            />
          </Case>
          <Case condition={isFunctionNode}>
            <Diamond
              width={width}
              height={height}
              fill={fill}
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              status={status}
              showSuccessAnimation={showSuccessAnimation}
              setShowSuccessAnimation={setShowSuccessAnimation}
              hoverClass={hoverClass}
            />
          </Case>
          <Case condition={isWebhook}>
            <Triangle
              width={width}
              height={height}
              fill={fill}
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              status={status}
              showSuccessAnimation={showSuccessAnimation}
              setShowSuccessAnimation={setShowSuccessAnimation}
              hoverClass={hoverClass}
            />
          </Case>
          <Case condition={isSubNode}>
            <Hexagon
              width={width}
              height={height}
              fill={fill}
              strokeColor={strokeColor}
              strokeWidth={strokeWidth}
              status={status}
              showSuccessAnimation={showSuccessAnimation}
              setShowSuccessAnimation={setShowSuccessAnimation}
              hoverClass={hoverClass}
            />
          </Case>
        </Switch>
      </svg>
    </Box>
  );
}

export default Shape;
