import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

function PageWrapper({ children }: { children: ReactNode }) {
  return (
    <Box maxW="1300px" w="100%" margin="0 auto" pt={8} px={4}>
      {children}
    </Box>
  );
}

export default PageWrapper;
