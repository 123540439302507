import React, { memo, useCallback, useEffect, useState } from 'react';
import { Box, Flex, useColorMode } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import Markdown from 'components/Markdown';
import config from 'config';
import useGraph from 'hooks/api/useGraph';
import useNodeModel from 'hooks/api/useNodeModel';
import useUpdateNodeFile from 'hooks/api/useUpdateNodeFile';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { PatternsNodeProps } from 'types';
import { getColorById, hexToRgb } from 'views/Graph/modules/GraphView/utils';
import useStore from 'views/Graph/state';

import NodeMenu from './components/NodeMenu';
import NodeResizer from './components/NodeResizer';

const getBgColor = ({ color, isDarkMode }: { color: string | null; isDarkMode: boolean }) => {
  if (!color) {
    return 'transparent';
  }

  if (color) {
    const rgb = hexToRgb(color);
    if (rgb) {
      return `rgba(${rgb.join(',')},0.5)`;
    }
  }

  return isDarkMode ? '#444' : '#fefefe';
};

const SHRINK_FACTOR = 2; // 1/2

function MarkdownNode({ id, data, selected }: PatternsNodeProps) {
  const { isHoverNode, isHoverMenuNode, setHoverNodeId, nodeChanges } = useStore(
    useCallback(
      (state) => ({
        isHoverNode: state.hoverNodeId === id,
        isHoverMenuNode: state.hoverMenuNodeId === id,
        setHoverNodeId: state.setHoverNodeId,
        nodeChanges: state.nodeChanges[id],
      }),
      [id]
    ),
    shallow
  );

  const { readOnly } = useReadOnlyMode();

  const { colorMode } = useColorMode();

  const isDarkMode = colorMode === 'dark';
  const nodeColor = getColorById(data?.display?.color || null, isDarkMode);
  const bgColor = getBgColor({ color: nodeColor, isDarkMode });

  const [isHoverNodeWithDelay, setIsHoverNodeWithDelay] = useState(false);
  // only used isHoverMenuNode (not isHoverNode) so menus only show when actually hovering over the node
  useEffect(() => {
    if (isHoverMenuNode) {
      const timer = setTimeout(() => {
        setIsHoverNodeWithDelay(true);
      }, 400);
      return () => clearTimeout(timer);
    } else {
      setIsHoverNodeWithDelay(false);
    }
  }, [isHoverMenuNode, setIsHoverNodeWithDelay]);

  const [tempWidth, setTempWidth] = useState<null | number>(null);
  const [tempHeight, setTempHeight] = useState<null | number>(null);

  const width = tempWidth || (nodeChanges?.width || data.display?.width || 1) * config.graphGrid.x;
  const height =
    tempHeight || (nodeChanges?.height || data.display?.height || 1) * config.graphGrid.y;

  const { mutate: updateNodeFile } = useUpdateNodeFile();
  const { data: versionId } = useGraph({
    select: (data) => data.version_uid,
  });

  const {
    data: { code },
  } = useNodeModel(id, data?.filePath);

  const onChange = useCallback(
    (newValue: string) => {
      if (!versionId) {
        return;
      }
      updateNodeFile({
        graph_version_uid: versionId,
        filePath: data.filePath!,
        fileName: data.name,
        fileContent: newValue,
        overrideCache: true,
      });
    },
    [data.filePath, data.name, versionId, updateNodeFile]
  );

  return (
    <div
      className={(readOnly ? 'nodrag' : '') + ' patternsNode'}
      style={{ cursor: readOnly ? 'pointer' : 'initial' }}
      data-name={data.name?.toLocaleLowerCase().replaceAll(' ', '-')}
      onMouseEnter={() => setHoverNodeId(id, true)}
      onMouseLeave={() => setHoverNodeId(null)}
    >
      <NodeResizer
        id={id}
        isVisible={selected && !readOnly}
        setTempWidth={setTempWidth}
        setTempHeight={setTempHeight}
      />

      <Box width={width} height={height}>
        {!readOnly && isHoverNodeWithDelay && (
          <NodeMenu
            id={id}
            showEdit={false}
            showExecute={false}
            showDuplicate
            showResetStore={false}
            showResetState={false}
            offset={10}
          />
        )}
        <Flex
          flex={1}
          transform={`scale(${1 / SHRINK_FACTOR})`}
          position="absolute"
          left={0}
          top={0}
          width={`${100 * SHRINK_FACTOR}%`}
          height={`${100 * SHRINK_FACTOR}%`}
          transformOrigin="left top"
          borderColor={selected || isHoverNode || isHoverMenuNode ? 'border3' : 'border1'}
          borderStyle="solid"
          borderRadius="0"
          borderWidth={2}
          p={4}
          bg={bgColor}
          overflowY="auto"
          className="customScrollBar"
        >
          <Markdown onChange={onChange} graphUID={data.graphUID}>
            {code || data.fileContents}
          </Markdown>
        </Flex>
      </Box>
    </div>
  );
}

export default memo(MarkdownNode);
