import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import posthog from 'posthog-js';

import { MarketplaceAppTemplate } from 'types/api';
import { useMarketplaceAppURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';

export default function useMarketplaceApp(
  uid: string,
  options?: UseQueryOptions<MarketplaceAppTemplate>
) {
  const url = useMarketplaceAppURL(uid);

  async function getApp(): Promise<MarketplaceAppTemplate> {
    if (process.env.NEXT_PUBLIC_ENABLE_POSTHOG !== 'false' && posthog) {
      posthog.capture('marketplace-app', { uid: uid });
    }
    const res = await Axios.get<MarketplaceAppTemplate>(url);
    return res.data;
  }

  return useQuery<MarketplaceAppTemplate>(['marketplace', 'apps', uid], getApp, {
    ...options,
    enabled: !!uid,
  });
}
