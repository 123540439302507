import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, ChakraProps, Flex, Input, Text, Tooltip, useToast } from '@chakra-ui/react';

import config from 'config';
import { useGraphAddedNodeId } from 'hooks/api/useGraph';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import { NodeData } from 'types';
import { isStoreNodeType } from 'utils/nodes';
import useStore from 'views/Graph/state';

type NodeNameProps = ChakraProps & {
  nodeId: string;
  isEditable: boolean;
  nodeData: NodeData;
};

const style = {
  fontWeight: 600,
  paddingX: 1, // Padding for adjacent titles
  fontSize: 10,
  lineHeight: '1.2',
};

function nameIsValidForNodeType(name: string, type: NodeData['type']) {
  if (isStoreNodeType(type)) {
    return new RegExp('^[a-zA-Z0-9_]+$').test(name);
  }
  return true;
}

function EditableNodeName({ nodeId, nodeData, isEditable = true }: NodeNameProps) {
  const [nodeName, setNodeName] = useState(nodeData.name);
  const { mutate: updateNodes } = useUpdateNodes();
  const toast = useToast();
  const openTab = useStore((state) => state.openTab);

  // breaks the title into words (separated by underscores) so the wordbreak works correctly
  const title = useMemo(() => {
    const words = (
      nodeData.resolvedStorage ? `${nodeData.resolvedStorage.definition}/${nodeName}` : nodeName
    ).split('_');
    return words.map((word, index) => (
      <React.Fragment key={index}>
        {word + (index < words.length - 1 ? '_' : '')}
        <wbr />
      </React.Fragment>
    ));
  }, [nodeName, nodeData.resolvedStorage]);

  const addedNodeId = useGraphAddedNodeId();

  const errorToast = useCallback(
    (message: string) => {
      toast({
        description: message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setNodeName(nodeData.name);
    },
    [nodeData.name, toast]
  );

  const onSubmitName = useCallback(
    (title: string) => {
      setIsEditing(false);
      if (title.length > 0) {
        if (!nameIsValidForNodeType(title, nodeData.type)) {
          errorToast(
            'Invalid table name. Table names can only contain letters, numbers, and underscores.'
          );
        } else {
          if (isStoreNodeType(nodeData.type)) {
            // if this is a store node, then don't update title, update store_name
            updateNodes({ [nodeId]: { store_name: title } });
          } else {
            updateNodes({ [nodeId]: { title } });
          }
        }
      } else {
        errorToast('Node name cannot be empty');
      }
    },
    [nodeId, nodeData.type, errorToast, updateNodes]
  );

  useEffect(() => {
    setNodeName(nodeData.name);
  }, [nodeData.name]);

  const [isEditing, setIsEditing] = useState(false);

  const handleKeyDown = (e: any) => {
    if (e.code === 'Enter') {
      setIsEditing(false);
      onSubmitName(nodeName);
    }
  };
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (addedNodeId === nodeId) {
      setIsEditing(true);
    }
  }, [addedNodeId, nodeId]);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    ref?.current && setWidth(ref?.current.offsetWidth);
  }, [nodeName, isEditing]);

  return (
    <Flex alignItems="center" onClick={(e) => e.stopPropagation()}>
      {!isEditing ? (
        <Text
          {...style}
          textAlign={'center'}
          cursor={'text'}
          noOfLines={2}
          width={`${config.graphGrid.x}px`}
        >
          {!!nodeData.trigger && (
            <>
              {/* this is the clock emoji for showing nodes that have a schedule attached to them*/}
              <Tooltip label={nodeData.trigger} placement="top">
                <Box
                  as="span"
                  onClick={() => openTab({ name: nodeId, topTab: 'Settings' })}
                  cursor="pointer"
                >
                  &#128344;
                </Box>
              </Tooltip>{' '}
            </>
          )}
          <span onClick={() => isEditable && setIsEditing(true)}>{title}</span>
        </Text>
      ) : (
        <Flex className="nodrag">
          <Input
            py={1}
            _focus={{
              boxShadow: 'none',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: !nameIsValidForNodeType(nodeName, nodeData.type)
                ? 'nodeError'
                : 'action',
            }}
            onBlur={() => onSubmitName(nodeName)}
            autoFocus={true}
            onFocus={(event) => event.target.select()}
            onKeyDown={handleKeyDown}
            {...style}
            onChange={(e) => setNodeName(e.target.value)}
            value={nodeName}
            height="100%"
            width={`${width + 2}px`}
            minHeight="22px"
            mt={-1}
            maxLength={64}
          />
          <Box ref={ref} {...style} opacity={0} position="absolute" zIndex={-1} whiteSpace="nowrap">
            {nodeName.replaceAll(' ', '.')}
            {/*replace spaces with periods so they don't get removed*/}
          </Box>
        </Flex>
      )}
    </Flex>
  );
}

export default EditableNodeName;
