import { useEffect, useReducer } from 'react';

import { Graph, ManifestNode } from 'types/api';
import { getFileName } from 'views/Graph/modules/Sidebar/FilesView/utils';

import useGraph from './api/useGraph';

export type FileOrFolder = {
  id: string;
  nodeId?: string;
  fileName: string;
  path: string;
  editMode: boolean;
  type: 'file' | 'folder';
  readOnly: boolean;
  content?: string;
};

export type Action = {
  type: 'SET' | 'SET_FILES' | 'REMOVE';
  payload: FilesState | FileStore | string;
};

type FileStore = {
  [key: string]: FileOrFolder;
};

export type FilesState = {
  selectedFile?: string;
  expandedFolders: string[];
  files: FileStore;
};

function reducer(state: FilesState, action: Action): FilesState {
  switch (action.type) {
    case 'SET':
      return action.payload as FilesState;
    case 'SET_FILES':
      return {
        ...state,
        files: action.payload as FileStore,
      };
    case 'REMOVE':
      delete state.files[action.payload as string];
      return {
        ...state,
      };
    default:
      throw new Error();
  }
}

export default function useFileSystem(defaultGraph?: Pick<Graph, 'manifest' | 'graph_files'>) {
  const { data: _graph } = useGraph({
    select: (g) => ({ manifest: g.manifest, graph_files: g.graph_files }),
    enabled: !defaultGraph,
  });

  const graph = defaultGraph || _graph;

  const [fileState, filesDispatcher] = useReducer(reducer, { files: {}, expandedFolders: [] });

  useEffect(() => {
    if (graph?.graph_files && graph?.manifest) {
      const nodes = graph.manifest?.nodes || [];
      const paths = Object.keys(graph.graph_files);

      const initialFileState = paths.reduce<FileStore>((accumulator, path) => {
        const node = nodes.find((node: ManifestNode) => node.file_path === path);
        const fileName = getFileName(path);
        if (path.endsWith('/')) {
          const pathNormalized = path.slice(0, path.length - 1);
          accumulator[pathNormalized] = {
            id: pathNormalized,
            type: 'folder',
            fileName: fileName,
            path: pathNormalized,
            readOnly: false,
            editMode: false,
            nodeId: node?.id,
            content: graph.graph_files[fileName],
          };
        } else {
          const isImage = fileName.match(
            /.*.(png|jpg|jpeg|svg|mp4|gif|jfif|pjpeg|pjp|webp|apng|avif)$/
          );
          accumulator[path] = {
            id: path,
            type: 'file',
            fileName: fileName,
            path: path,
            readOnly: !!isImage,
            editMode: false,
            nodeId: node?.id,
            content: graph.graph_files[fileName],
          };
        }
        return accumulator;
      }, {});
      filesDispatcher({
        type: 'SET_FILES',
        payload: initialFileState,
      });
    }
  }, [graph?.graph_files, graph?.manifest, filesDispatcher]);

  return { fileState, filesDispatcher };
}
