import useChartNodeData from './api/useChartNodeData';
import useVegaSpecInner, { VegaSpecResult } from './useVegaSpecInner';

function useVegaSpecMarketplace(
  nodeId: string | null,
  nodeTitle: string | null,
  nodeDataId: string | null,
  code: string | undefined,
  nodeType: string | null,
  graphUID?: string
): VegaSpecResult {
  const {
    data: { data: chartData, dataTrimmed },
  } = useChartNodeData(nodeDataId, 'table', graphUID);

  return useVegaSpecInner(code, nodeType, nodeTitle, chartData, dataTrimmed);
}

export default useVegaSpecMarketplace;
