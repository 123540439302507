import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChakraProps, Flex, Tooltip } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { VisualizationSpec } from 'vega-embed';

import { H5 } from 'components/Typography';
import VegaChart from 'components/VegaChart';
import useGraph from 'hooks/api/useGraph';
import useVegaSpecMarketplace from 'hooks/useVegaSpecMarketplace';
import { NodeData } from 'types';

import NodeIcon from './NodeIcon';

type NodeVisualizationProps = ChakraProps & {
  id: string;
  strokeColor: string;
  data: NodeData;
  width: number;
  height: number;
  graphUID?: string;
};

const autosizeOptions = {
  type: 'fit',
  contains: 'padding',
};

function NodeVisualization({
  id,
  strokeColor,
  data,
  width,
  height,
  graphUID,
}: NodeVisualizationProps) {
  const wrapper = useRef<HTMLDivElement>(null);

  const { data: graphFiles } = useGraph({
    select: (graph) => graph.graph_files,
  });

  const fileContents = useMemo(() => {
    if (!data.filePath || !graphFiles) return '';
    return graphFiles[data.filePath];
  }, [data.filePath, graphFiles]);

  const {
    spec: vegaSpecOriginal,
    data: visData,
    error: specError,
    dataTrimmed,
  } = useVegaSpecMarketplace(
    id,
    data.name,
    (data.local_input_edges.length > 0 && data.local_input_edges[0].source) || null,
    fileContents,
    data.type || null,
    graphUID
  );

  const vegaSpec = useMemo(() => {
    return (
      vegaSpecOriginal &&
      ({
        ...vegaSpecOriginal,
        width: width - 25,
        height: height - 25,
        autosize: autosizeOptions,
      } as VisualizationSpec)
    );
  }, [vegaSpecOriginal, width, height]);

  const [vegaSpecError, setVegaSpecError] = useState<string | null>(null);

  useEffect(() => {
    setVegaSpecError(null);
  }, [vegaSpec, setVegaSpecError]);

  const error = useMemo(() => {
    let error = 'No chart data';
    if (specError || vegaSpecError) {
      error =
        specError === 'No chart spec provided'
          ? 'No chart spec provided'
          : 'Error parsing chart spec';
    }
    return error;
  }, [specError, vegaSpecError]);

  const renderCanvas = visData.table.length > 50;

  const onError = useCallback(
    (error: Error) => setVegaSpecError(error.message),
    [setVegaSpecError]
  );

  return (
    <Flex
      borderColor={colors.gray[500]}
      borderWidth={1}
      borderStyle="solid"
      padding={1}
      bg={(vegaSpec?.config?.background as string) || 'white'}
      borderRadius={6}
      ref={wrapper}
      width={`${width}px`}
      height={`${height}px`}
    >
      {vegaSpec && visData?.table.length > 0 && !vegaSpecError ? (
        <VegaChart
          key={`${data.display?.width}-${data.display?.height}`}
          spec={vegaSpec}
          data={visData.table}
          onError={onError}
          renderer={renderCanvas ? 'canvas' : 'svg'} // Over 50 data points hurts performance but svg will be sharper and allow tooltips
          disableTooltips={renderCanvas}
          className="customScrollBar"
          dataTrimmed={dataTrimmed}
        />
      ) : (
        <Tooltip
          label={specError || vegaSpecError}
          hasArrow={true}
          isDisabled={!specError && !vegaSpecError}
          placement={'right'}
        >
          <Flex
            width="100%"
            height="100%"
            flexGrow={1}
            alignItems="center"
            justifyContent="center"
            flexDir="column"
          >
            <NodeIcon nodeId={id} nodeData={data} color={strokeColor} position={'relative'} />
            <H5
              mx="15px"
              textAlign={'center'}
              mb="-5px"
              fontWeight={500}
              fontSize={10}
              lineHeight="1.2"
              zIndex={2}
            >
              {error}
            </H5>
          </Flex>
        </Tooltip>
      )}
    </Flex>
  );
}

export default memo(NodeVisualization);
