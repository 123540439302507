import { useContext, useEffect, useMemo, useRef } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Flex } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import { sourceCode } from 'styles/fonts';

import { FileContext } from 'contexts/FileContext';
import { syntaxDark } from 'utils/darktheme';
import FilesViewPreview from 'views/Graph/modules/Sidebar/FilesView/FilesViewPreview';

const CodePreviewer = ({ selectedFilePath }: { selectedFilePath?: string }) => {
  const { fileState, filesDispatcher } = useContext(FileContext);

  const scollToRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    selectedFilePath && scollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
    if (selectedFilePath && fileState?.files && filesDispatcher) {
      const selectFile = Object.keys(fileState.files).find((file) => file == selectedFilePath);
      if (selectFile) {
        filesDispatcher({ type: 'SET', payload: { ...fileState, selectedFile: selectFile } });
      }
    }
  }, [selectedFilePath, fileState?.files, filesDispatcher, scollToRef, fileState]);

  useEffect(() => {
    if (!fileState?.selectedFile && fileState?.files) {
      // pick the first python (or if no python, first sql) file
      const firstPythonFile = Object.keys(fileState.files).find(
        (file) => file.endsWith('.py') && file != '__init__.py'
      );
      const firstSqlFile = Object.keys(fileState.files).find((file) => file.endsWith('.sql'));

      filesDispatcher({
        type: 'SET',
        payload: {
          ...fileState,
          selectedFile: firstPythonFile || firstSqlFile || Object.keys(fileState.files)[0],
        },
      });
    }
  }, [fileState, filesDispatcher]);

  const fileContent = useMemo(() => {
    if (!fileState?.selectedFile || !fileState?.files) return '';
    return fileState.files[fileState.selectedFile]?.content;
  }, [fileState?.selectedFile, fileState?.files]);

  /*
   * List of available languages here: https://github.com/react-syntax-highlighter/react-syntax-highlighter/blob/master/AVAILABLE_LANGUAGES_HLJS.MD
   */
  const language = useMemo(() => {
    if (!fileState?.selectedFile) return;
    const fileParts = fileState?.selectedFile.split('.');
    const fileExtension = fileParts[fileParts.length - 1];
    switch (fileExtension) {
      case 'py':
        return 'python';
      case 'md':
        return 'markdown';
      case 'yml':
        return 'yaml';
      default:
        return fileExtension;
    }
  }, [fileState?.selectedFile]);

  return (
    <Flex flex={1} bg={colors.dark.bg1} height={600} borderRadius={10} ref={scollToRef}>
      <Flex marginRight={7} paddingTop={2}>
        <FilesViewPreview
          customSort={(a, b) => {
            // hack to ensure python files are always first, then sql, then markdown
            function ranker(x: string) {
              if (x.endsWith('.md')) {
                return '__' + x;
              } else if (x.endsWith('.sql')) {
                return '___' + x;
              } else if (x.endsWith('.py') && x != '__init__.py') {
                return '____' + x;
              } else {
                return '_' + x;
              }
            }
            return ranker(a).localeCompare(ranker(b)) ? 1 : -1;
          }}
        />
      </Flex>
      <Flex direction="column" flex={1} width="100%" overflow="auto" borderTopRightRadius={10}>
        <Flex
          className={sourceCode.className}
          bg={colors.dark.bg4}
          color={colors.dark.text3}
          paddingY={2}
          paddingX={4}
          fontSize="16px"
        >
          {fileState?.selectedFile}
        </Flex>
        <Flex bg={colors.dark.bg1} overflow="hidden" height="100%">
          <SyntaxHighlighter
            style={{ ...syntaxDark }}
            customStyle={{ fontSize: '14px', width: '100%' }}
            language={language}
            showLineNumbers
          >
            {String(fileContent).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CodePreviewer;
