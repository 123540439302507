import React from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { Flex, Icon, MenuItem, Spacer } from '@chakra-ui/react';

import { GraphNode } from 'types';
import { getIconForNodeType } from 'utils/nodes';

type SelectStoreMenuItemProps = {
  selectedStore?: GraphNode;
  store: GraphNode;
  onSelect: (storeId: string | null) => void;
  isImplicit: boolean;
};

export default function SelectStoreMenuItem({
  selectedStore,
  onSelect,
  store,
  isImplicit,
}: SelectStoreMenuItemProps) {
  const IconComponent = getIconForNodeType(store.data);

  const isCurrentSelection = selectedStore && selectedStore.data.id === store.data.id;
  return (
    <MenuItem
      icon={<Icon as={IconComponent} w="11px" height="auto" pt={1} />}
      bg={selectedStore && selectedStore.data.id === store.data.id ? 'bg3' : 'transparent'}
      onClick={() => onSelect(store.data.id)}
      fontSize={'xs'}
    >
      <Flex direction={'row'}>
        {store.data.name} {isCurrentSelection && isImplicit && '(implicit connection)'}
        {isCurrentSelection && (
          <>
            <Spacer />
            <Icon as={BsCheck2} width="12px" height="auto" />
          </>
        )}
      </Flex>
    </MenuItem>
  );
}
