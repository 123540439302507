import { getHoverProps, ShapeProps } from './common';

function Rectangle({ width, height = 50, fill, strokeColor, strokeWidth, hoverClass }: ShapeProps) {
  return (
    <>
      <rect rx={5} ry={5} width={width} height={height} {...getHoverProps(hoverClass)} />
      <rect
        rx={5}
        ry={5}
        width={width}
        height={height}
        stroke={strokeColor}
        fill={fill}
        strokeWidth={strokeWidth}
      />
    </>
  );
}

export default Rectangle;
