import { createContext, ReactElement, ReactNode, useMemo } from 'react';

import useFileSystem, { Action as FileStateAction, FilesState } from 'hooks/useFileSystem';
import { Graph } from 'types/api';

export type FileContextProps = {
  fileState?: FilesState;
  filesDispatcher: (action: FileStateAction) => void;
};

export const FileContext = createContext<FileContextProps>({
  filesDispatcher: () => {},
});

type FileContextProviderProps = {
  graph?: Graph;
  children?: ReactNode;
};

const FileContextProvider = ({ graph, children }: FileContextProviderProps): ReactElement => {
  const { fileState, filesDispatcher } = useFileSystem(graph);

  const value = useMemo(() => {
    return { fileState, filesDispatcher };
  }, [fileState, filesDispatcher]);

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};

export default FileContextProvider;
