import { UseMutationOptions } from '@tanstack/react-query';

import useGraph from 'hooks/api/useGraph';
import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { AddWebhookData } from 'types/index';
import { useAddWebhookURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

function useAddWebhook(options?: UseMutationOptions<Graph, unknown, AddWebhookData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });

  const graphVersionUID = graph?.version_uid;
  const url = useAddWebhookURL(graphVersionUID);

  const result = useGraphEditMutate<Graph, unknown, AddWebhookData>(
    async (data: AddWebhookData): Promise<Graph> => {
      const res = await Axios.post<Graph>(url, {
        ...data,
        add_output_stores: data.addOutputStores,
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useAddWebhook;
