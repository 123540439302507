import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useMovefileURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

type MoveFileData = {
  source: string | null;
  destination: string | null;
};

function useMovefile(options?: UseMutationOptions<Graph, unknown, MoveFileData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const url = useMovefileURL(graphVersionUID);

  const result = useGraphEditMutate<Graph, unknown, MoveFileData>(
    async ({ destination, source }: MoveFileData) => {
      const res = await Axios.post<Graph>(url, {
        source,
        destination,
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useMovefile;
