import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import ReactFlow, {
  ConnectionMode,
  Controls,
  Edge,
  MarkerType,
  ProOptions,
  ReactFlowProvider,
} from 'reactflow';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Spinner,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import Head from 'next/head';
import { colors } from 'styles/colors';

import CodePreviewer from 'components/CodePreviewer';
import CloneTemplateButton from 'components/CreateAppButton/CloneTemplateButton';
import DuplicateGraph from 'components/DuplicateGraph';
import { NodeBreakdown } from 'components/GraphCard/GraphCard';
import Markdown from 'components/Markdown';
import ShowHide from 'components/ShowHide';
import { H4 } from 'components/Typography';
import config from 'config';
import FileContextProvider from 'contexts/FileContext';
import useMarketplaceApp from 'hooks/api/useMarketplaceApp';
import useMarketplaceGraph from 'hooks/api/useMarketplaceGraph';
import useMarketplaceRelatedItems from 'hooks/api/useMarketplaceRelatedItems';
import { useShortDescriptionCustom } from 'hooks/api/useShortDescription';
import { GraphNode } from 'types';
import { makeGraphURL } from 'utils';
import { accumulateNodes, isStoreNodeType } from 'utils/nodes';
import { parseGraphManifest } from 'views/Graph/hooks/useParseGraphManifest';
import Background, { BgVariant } from 'views/Graph/modules/GraphView/Background/Background';
import SmartEdge from 'views/Graph/modules/GraphView/EdgeTypes/SmartEdge';
import { LoadingNode, MarkdownNode, PatternsNode } from 'views/Graph/modules/GraphView/NodeTypes';
import ItemPageLayout from 'views/Marketplace/Layout/ItemPageLayout';
import Section from 'views/Marketplace/Section';

import GoogleIcon from '../../../../public/images/connections/google-cloud.svg';
import PostgresIcon from '../../../../public/images/connections/postgres.svg';

const nodeTypes = {
  patterns: PatternsNode,
  markdown: MarkdownNode,
  loading: LoadingNode,
};

const edgeTypes = {
  smart: SmartEdge,
};

const proOptions: ProOptions = {
  hideAttribution: true,
  account: 'paid-custom',
};

const defaultEdgeOptions = {
  type: 'smart',
  markerEnd: {
    type: MarkerType.ArrowClosed,
  },
};

type AppPreviewProps = {
  uid: string;
  showHeader?: boolean;
  setItem?: (type: string, uid: string) => void;
  hideRelatedItems?: boolean;
  onBack?: (event: MouseEvent) => void;
  createAppFlow?: boolean;
};

const AppPreview = ({
  uid,
  setItem,
  showHeader,
  hideRelatedItems,
  onBack,
  createAppFlow = false,
}: AppPreviewProps) => {
  const { data: app, isLoading } = useMarketplaceApp(uid);
  const { data: relatedItems, isLoading: isLoadingRelated } = useMarketplaceRelatedItems(
    'apps',
    uid
  );

  const { data: graph } = useMarketplaceGraph(app?.graph_uid);

  const graphNodes = useMemo(() => graph?.manifest.nodes || [], [graph?.manifest.nodes]);

  const tableCount = useMemo(() => {
    if (!graphNodes) return 0;
    return graphNodes.filter((node) => isStoreNodeType(node.node_type)).length;
  }, [graphNodes]);

  const tableEngine = useMemo(() => {
    if (!graphNodes) return 'Postgres';
    const tableNodes = graphNodes.filter((node) => isStoreNodeType(node.node_type));
    if (tableNodes.length <= 0) return 'Postgres';
    return tableNodes[0].resolved_storage?.engines[0] || 'Postgres';
  }, [graphNodes]);

  const nodeAccumulator = useMemo(() => accumulateNodes(graphNodes), [graphNodes]);

  const markdownRef = useRef<HTMLDivElement>(null);

  const shortDescription = useShortDescriptionCustom(app?.description);

  const [nodes, setNodes] = useState<GraphNode[]>([]);
  const [edges, setEdges] = useState<Edge<any>[]>([]);
  useEffect(() => {
    async function parse() {
      if (graph?.manifest) {
        const r = await parseGraphManifest(graph?.manifest, 'root');
        setNodes(r.nodes);
        setEdges(r.edges);
      }
    }
    parse();
  }, [graph?.manifest]);

  const augmentedNodes = useMemo(() => {
    // pass in the file contents and graphUID to the node data for markdown and chart nodes
    return nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          fileContents: node?.data?.filePath ? graph?.graph_files[node?.data?.filePath] : undefined,
          graphUID: graph?.uid,
        },
      };
    });
  }, [nodes, graph?.graph_files, graph?.uid]);

  const [selectedFile, setSelectedFile] = useState<string | undefined>(undefined);

  const renderHeader = () => {
    if (!uid || isLoading) return <></>;

    if (!app) {
      return (
        <Box textAlign="center">
          <Text fontWeight={600} color="dark.bg1">
            App {uid} not found
          </Text>

          {onBack && (
            <Button fontSize="14px" py={4} px={4} mt={2} onClick={(event) => onBack?.(event)}>
              Go back to Marketplace
            </Button>
          )}
        </Box>
      );
    }

    return (
      <Flex
        justify="space-between"
        align={{ base: 'center', md: 'flex-start' }}
        gap={5}
        direction={{ base: 'column', md: 'row' }}
      >
        <Flex flexDirection="column" flex={1} gap={1}>
          <Text
            fontSize="24px"
            fontWeight={700}
            lineHeight="1.5"
            letterSpacing="-0.01em"
            color="dark.bg1"
          >
            {app.title}
          </Text>

          {app.tags.length > 0 && (
            <Wrap spacing={2}>
              {app.tags.map((tag) => (
                <WrapItem
                  key={tag}
                  borderRadius="full"
                  border="1px"
                  borderColor="light.border1"
                  color="light.text3"
                  px={3}
                  py={1}
                  _hover={{ cursor: 'pointer' }}
                >
                  #{tag}
                </WrapItem>
              ))}
            </Wrap>
          )}

          <Box width="100%" maxW={'100%'}>
            <ShowHide maxHeight={150} childRef={markdownRef}>
              <Markdown ref={markdownRef} graphUID={uid}>
                {app.description || 'No readme'}
              </Markdown>
            </ShowHide>
          </Box>

          <Flex gap={5} mt={5} justify={{ base: 'center', md: 'flex-start' }}>
            {createAppFlow ? (
              <CloneTemplateButton label="Use Template" template={app} isPreview onClick={onBack} />
            ) : (
              <DuplicateGraph key="clone" graphUID={app.graph_uid} menuButton="default" />
            )}
            <Button
              key="view"
              size="md"
              fontSize={12}
              minW={100}
              variant="outline"
              onClick={() => {
                window.open(makeGraphURL(app.graph_uid), '_blank', 'noopener,noreferrer');
              }}
            >
              View Graph
            </Button>
          </Flex>
        </Flex>

        <Flex flexDirection="column" fontSize="14px" p={2} maxWidth={'300px'}>
          <H4>Infrastructure and Usage</H4>
          <Flex alignItems="flex-end" mt={2}>
            <Flex flexDirection="column" padding={2}>
              {/* Will eventually need to change hardcoded icon and value */}
              <GoogleIcon width={30} />
              <Text>Google Cloud</Text>
            </Flex>
          </Flex>

          <Divider color="black" />

          <Flex alignItems="flex-end" mt={2} gap={5}>
            <Flex flexDirection="column" padding={2}>
              {/* Will eventually need to change hardcoded icon */}
              <PostgresIcon width={30} />
              <Text>{tableEngine}</Text>
            </Flex>
            <Flex flexDirection="column" padding={2}>
              <H4>{tableCount}</H4>
              <Text>Tables</Text>
            </Flex>
          </Flex>

          <H4 mt={5}>Nodes</H4>
          <Flex alignItems="flex-end" my={5}>
            <NodeBreakdown nodes={nodeAccumulator} />
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderData = () => {
    if (!uid || isLoading) {
      return (
        <Center textAlign="center" height="100%" width="100%">
          <Spinner size="md" />
          <Text fontWeight={500} color="dark.bg1" ml={4}>
            Loading app...
          </Text>
        </Center>
      );
    }

    if (!app) return <></>;

    return (
      <ReactFlowProvider>
        <Flex position="relative" height={{ sm: '200px', md: '400px' }} width="100%" margin="auto">
          <ReactFlow
            nodes={augmentedNodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            minZoom={0.25}
            nodesConnectable={false}
            style={{ background: '#F5F5F5' }}
            proOptions={proOptions}
            fitView={true}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionMode={ConnectionMode.Loose}
            onNodeClick={(event, node) => {
              setSelectedFile(node.data?.filePath);
            }}
          >
            <Background
              variant={BgVariant.Dots}
              color={colors.gray[500]}
              gap={config.graphGrid.x}
              size={1}
            />
            <Controls showInteractive={false} />
          </ReactFlow>
        </Flex>

        <Box mt={10} display={{ base: 'none', md: 'block' }}>
          <CodePreviewer selectedFilePath={selectedFile} />
        </Box>

        {(relatedItems?.components?.results || []).length > 0 && !hideRelatedItems && (
          <Box w="full" mt={6}>
            <Section
              category={'components'}
              title={'Components used in this template'}
              items={relatedItems?.components?.results || []}
              isLoading={isLoadingRelated}
              setItemUID={(uid) => {
                setItem?.('components', uid || '');
              }}
            />
          </Box>
        )}
      </ReactFlowProvider>
    );
  };

  return (
    <FileContextProvider graph={graph}>
      <Head>
        <title>Patterns | Marketplace | {app?.title || app?.slug}</title>
        {shortDescription && <meta name="description" content={shortDescription} />}
      </Head>
      <ItemPageLayout heroContent={renderHeader()} {...{ showHeader, onBack }}>
        {renderData()}
      </ItemPageLayout>
    </FileContextProvider>
  );
};

export default AppPreview;
