import { useMemo } from 'react';

import useGraph from './useGraph';

function useReadme() {
  const { data } = useGraph({
    select: (data) => {
      const { graph_files, manifest } = data;
      return { graph_files, manifest };
    },
  });

  const readme = useMemo(() => {
    if (!data) return;
    const { graph_files: graphFiles, manifest } = data;
    if (!manifest?.description_path) return;
    const key =
      graphFiles && Object.keys(graphFiles).find((file) => file === manifest.description_path);
    if (!key) return;
    return graphFiles[key];
  }, [data]);

  return readme;
}

export default useReadme;
