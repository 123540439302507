import { memo } from 'react';
import { ChakraProps, Divider, Flex } from '@chakra-ui/react';
import { colors } from 'styles/colors';

import { NodeData } from 'types';
import { isFunctionNodeType, isStoreNodeType } from 'utils/nodes';
import TableNodePanel from 'views/Graph/modules/Dashboard/GridItem/TableNodePanel';
import NodePreviewHeader from 'views/Graph/modules/NodePreview/NodePreviewHeader';

import NodeFormFunction from './NodeFormFunction';

type NodeFormProps = ChakraProps & {
  id: string;
  strokeColor: string;
  data: NodeData;
  width: number;
  height: number;
  hoverClass: string;
};

const SHRINK_FACTOR = 2; // 1/2

const NodeForm = ({ id, strokeColor, data, width, height, hoverClass }: NodeFormProps) => {
  const isStore = isStoreNodeType(data?.type);
  const isFunction = isFunctionNodeType(data?.type);

  return (
    <Flex width={`${width}px`} height={`${height}px`} overflow="hidden" zIndex={3}>
      <Flex
        flex={1}
        transform={`scale(${1 / SHRINK_FACTOR})`}
        position="absolute"
        left={0}
        top={0}
        borderRadius={10}
        width={`${width * SHRINK_FACTOR}px`}
        height={`${height * SHRINK_FACTOR}px`}
        transformOrigin="left top"
        className={`${hoverClass}`}
        margin="10px"
      >
        <Flex
          flex={1}
          boxShadow={`0 0 0 2px ${strokeColor}`}
          borderRadius={10}
          bg="white"
          flexDirection="column"
        >
          <Flex minH="25px" bg={colors.gray[200]} borderTopRadius={12} cursor="move" />
          <Flex
            flexDirection="column"
            cursor="default"
            flex={1}
            className="nodrag"
            overflowY="auto"
          >
            <NodePreviewHeader id={id} data={data} paddingX={2} paddingY={0} />
            <Divider />
            {isFunction && <NodeFormFunction {...{ id, data }} />}
            {isStore && <TableNodePanel nodeId={id} nodeData={data} />}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
export default memo(NodeForm);
