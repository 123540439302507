import { useStore as useRFStore } from 'reactflow';
import { Box } from '@chakra-ui/react';

import NodeButtonGroup from 'views/Graph/components/NodeButtonGroup';

type NodeMenuProps = {
  id: string;
  showExecute?: boolean;
  showEdit?: boolean;
  showDuplicate?: boolean;
  showResetStore?: boolean;
  showResetState?: boolean;
  showDelete?: boolean;
  isChart?: boolean;
  offset: number;
};

function NodeMenu({
  id,
  showExecute = true,
  showEdit = true,
  showDuplicate = true,
  showResetStore = true,
  showResetState = true,
  showDelete = true,
  isChart = false,
  offset,
}: NodeMenuProps) {
  // we need to reverse the scale so that the menu is not scaled
  const scale = useRFStore((s) => +(1 / s.transform[2]).toFixed(2));
  const clampedScale = Math.min(0.5 + scale / 2, 2);

  return (
    <Box
      position="absolute"
      left="50%"
      top={isChart ? -5 : `${offset - 35}px`}
      transform={`translate(-50%,0) scale(${clampedScale})`}
      zIndex={10}
      bg="bg1"
      borderColor="border2"
      borderWidth={1}
      borderStyle="solid"
      borderRadius={3}
    >
      <NodeButtonGroup
        id={id}
        showView={false}
        showEdit={showEdit}
        showExecute={showExecute}
        showDuplicate={showDuplicate}
        showResetStore={showResetStore}
        showResetState={showResetState}
        showDelete={showDelete}
      />
    </Box>
  );
}

export default NodeMenu;
