import React, { useCallback, useMemo } from 'react';
import { If, Then } from 'react-if';
import { Button, Flex } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import shallow from 'zustand/shallow';

import config from 'config';
import { GraphState, NodeData } from 'types';
import { isConfigured } from 'utils/nodes';
import useStore from 'views/Graph/state';

import EditableNodeName from './EditableNodeName';

type NodeTitleProps = {
  id: string;
  nodeData: NodeData;
  offset?: number;
};

const selector = (s: GraphState) => ({
  graphLevel: s.graphLevel,
  openTab: s.openTab,
});

function NodeTitle({ id, nodeData, offset = 0 }: NodeTitleProps) {
  const { graphLevel, openTab } = useStore(selector, shallow);

  const hasConfigErrors = useMemo(
    () => !isConfigured(nodeData, graphLevel),
    [graphLevel, nodeData]
  );

  const handleConfigure = useCallback(() => {
    openTab({ name: id, topTab: 'Settings', bottomTab: null });
  }, [id, openTab]);

  const isChart = useMemo(() => {
    return nodeData.type === 'chart';
  }, [nodeData.type]);

  return (
    <>
      <Flex
        data-titleid={id}
        data-testid="graph-node-title"
        position="absolute"
        transform="translate(-50%, 0)"
        justifyContent="center"
        alignItems="center"
        left="50%"
        zIndex={5}
        flexDirection="column"
        top={isChart ? 'calc(100% - 5px)' : `${config.graphGrid.y / 2 + 4 + offset}px`}
      >
        <If condition={nodeData.type != 'chart'}>
          <Then>
            <EditableNodeName nodeId={id} nodeData={nodeData} isEditable={true} />
          </Then>
        </If>
        <If condition={hasConfigErrors && graphLevel === 'root'}>
          <Then>
            <Button
              fontSize="10px"
              variant="link"
              color={colors.yellow[700]}
              size="xs"
              display="block"
              onClick={handleConfigure}
              _hover={{
                textDecoration: 'none',
                fontWeight: 800,
              }}
              _active={{
                color: colors.yellow[700],
              }}
            >
              configure
            </Button>
          </Then>
        </If>
      </Flex>
    </>
  );
}

export default NodeTitle;
