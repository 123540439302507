import { UseMutationOptions } from '@tanstack/react-query';

import useGraph from 'hooks/api/useGraph';
import useGetUniqueNodeName from 'hooks/useGetUniqueNodeName';
import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { AddNodeData } from 'types/index';
import { useAddFileURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import { generateRandomId } from 'views/Graph/components/SelectStore/SelectStoreMenu';
import useStore from 'views/Graph/state';

// used for adding any nodes that have files associated with them (python, sql, markdown) - except charts
function useAddNodeFile(options?: UseMutationOptions<Graph, unknown, AddNodeData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const url = useAddFileURL(graphVersionUID);
  const getUniqueNodeName = useGetUniqueNodeName();

  const result = useGraphEditMutate<Graph, unknown, AddNodeData>(
    async ({
      fileContents,
      description,
      iconUrl,
      nodeType,
      title,
      chart_input: chartInput,
      parentId,
      position,
      addOutputStores,
      fileName,
    }: AddNodeData): Promise<Graph> => {
      const suffix =
        (nodeType === 'python' && '.py') ||
        (nodeType === 'sql' && '.sql') ||
        (nodeType === 'markdown' && '.md') ||
        (nodeType === 'chart' && '.json') ||
        '.txt';

      fileName = fileName || `${getUniqueNodeName(nodeType, suffix)}${suffix}`;

      const file = new File([fileContents], fileName, {
        type: 'text/plain',
      });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('destination', fileName);
      formData.append('node_type', nodeType);
      formData.append('position_x', position.x.toString());
      formData.append('position_y', position.y.toString());
      if (typeof addOutputStores === 'boolean') {
        formData.append('add_output_stores', addOutputStores.toString());
      }

      if (parentId) {
        formData.append('parent_id', parentId);
      }
      if (description) {
        formData.append('description', description);
      }
      if (iconUrl) {
        formData.append('icon_url', iconUrl);
      }
      if (title) {
        formData.append('title', title);
      }
      if (chartInput || nodeType === 'chart') {
        formData.append('chart_input', chartInput || generateRandomId());
      }

      const res = await Axios.post<Graph>(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useAddNodeFile;
