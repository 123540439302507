import { MouseEvent, useCallback } from 'react';
import { FiCopy, FiEdit, FiEye, FiPlay, FiRotateCcw, FiTrash2 } from 'react-icons/fi';
import { ChakraProps, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import useAddNodeFromComponent from 'hooks/api/useAddNodeFromComponent';
import useDuplicateNode from 'hooks/api/useDuplicateNode';
import useGraph from 'hooks/api/useGraph';
import useTriggerGraph from 'hooks/api/useTriggerGraph';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { GraphState } from 'types';
import { isComponentNodeType } from 'utils/nodes';
import useStore from 'views/Graph/state';

type NodeButtonGroupProps = ChakraProps & {
  showView?: boolean;
  showEdit?: boolean;
  showExecute?: boolean;
  showDuplicate?: boolean;
  showResetStore?: boolean;
  showResetState?: boolean;
  showDelete?: boolean;
  className?: string;
  id: string;
};

const selector = (s: GraphState) => ({
  setExecutionPlanNode: s.setExecutionPlanNode,
  openTab: s.openTab,
  setNodeIdsToDelete: s.setNodeIdsToDelete,
  setResetNodeState: s.setResetNodeState,
  setResetStoreNode: s.setResetStoreNode,
});

function NodeButtonGroup({
  id,
  showView = true,
  showEdit = true,
  showExecute = true,
  showDuplicate = true,
  showResetStore = true,
  showResetState = true,
  showDelete = true,
  ...rest
}: NodeButtonGroupProps) {
  const {
    setExecutionPlanNode,
    openTab,
    setNodeIdsToDelete,
    setResetNodeState,
    setResetStoreNode,
  } = useStore(selector, shallow);
  const { mutate: trigger, isLoading: isTriggeringNode } = useTriggerGraph(id);
  const { mutate: duplicateNode, isLoading: isDuplicatingNode, isGraphSaving } = useDuplicateNode();
  const { mutate: addComponent, isLoading: isAddingNodeFromComponent } = useAddNodeFromComponent();
  const isLoading = isDuplicatingNode || isAddingNodeFromComponent;

  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });

  const { readOnly } = useReadOnlyMode();

  const handleExecutionHover = useCallback(() => {
    setExecutionPlanNode(id);
  }, [id, setExecutionPlanNode]);

  const clearExecutionPlan = useCallback(() => {
    setExecutionPlanNode(null);
  }, [setExecutionPlanNode]);

  const onOpenEdit = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      openTab(id);
    },
    [id, openTab]
  );

  const onOpenPreview = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      openTab(id);
    },
    [id, openTab]
  );

  const onTrigger = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();
      clearExecutionPlan();

      trigger();
      openTab(id);
    },
    [clearExecutionPlan, trigger, openTab, id]
  );

  const onDuplicate = useCallback(
    (evt: MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      const node = useStore.getState().nodes.find((node) => node.id === id);

      if (isComponentNodeType(node?.data.type)) {
        addComponent({
          componentKey: node?.data.fromComponent!,
          graphVersionUID: graph?.version_uid || '',
          position: {
            x: node ? Math.floor(node.position.x / 100) : 0,
            y: node ? Math.floor(node.position.y / 100) : 0,
          },
        });
      } else {
        duplicateNode(id);
      }
    },
    [duplicateNode, id, addComponent, graph]
  );

  const tooltipPlacement = 'top';

  return (
    <Flex {...rest}>
      {showView && (
        <Tooltip label={'View Details'} placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiEye />}
            aria-label="View Details"
            variant="ghost"
            mr={0}
            onClick={onOpenPreview}
            className="nodrag"
            size="sm"
          />
        </Tooltip>
      )}

      {showEdit && !readOnly && (
        <Tooltip label={'Edit'} placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiEdit />}
            aria-label="View Details"
            variant="ghost"
            onClick={onOpenEdit}
            className="nodrag"
            size="sm"
          />
        </Tooltip>
      )}
      {showExecute && !readOnly && (
        <Tooltip label="Run Node" placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiPlay />}
            aria-label="Execute Node"
            variant="ghost"
            className="nodrag"
            size="sm"
            onClick={(event) => onTrigger(event)}
            onMouseEnter={() => handleExecutionHover()}
            onMouseLeave={clearExecutionPlan}
            isLoading={isTriggeringNode}
          />
        </Tooltip>
      )}
      {showDuplicate && !readOnly && (
        <Tooltip label={'Duplicate Node'} placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiCopy />}
            aria-label="Duplicate Node"
            variant="ghost"
            className="nodrag"
            size="sm"
            data-testid={'graph-node-buttongroup-duplicate'}
            onClick={onDuplicate}
            isLoading={isLoading}
            disabled={isGraphSaving}
          />
        </Tooltip>
      )}
      {showResetStore && !readOnly && (
        <Tooltip label={'Reset Store'} placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiRotateCcw />}
            aria-label="ResetStore"
            variant="ghost"
            className="nodrag"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setResetStoreNode(id);
            }}
          />
        </Tooltip>
      )}

      {showResetState && !readOnly && (
        <Tooltip label={'Reset State'} placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiRotateCcw />}
            aria-label="ResetState"
            variant="ghost"
            className="nodrag"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setResetNodeState(id);
            }}
          />
        </Tooltip>
      )}

      {showDelete && !readOnly && (
        <Tooltip label="Delete Node" placement={tooltipPlacement}>
          <IconButton
            colorScheme="gray"
            icon={<FiTrash2 />}
            aria-label="Delete Node"
            variant="ghost"
            className="nodrag"
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setNodeIdsToDelete([id]);
            }}
          />
        </Tooltip>
      )}
    </Flex>
  );
}

export default NodeButtonGroup;
