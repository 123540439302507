import React, { memo } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

import { Label } from 'components/Typography';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { NODE_HEIGHT, NODE_WIDTH } from 'views/Graph/modules/GraphView/utils';

function LoadingNode() {
  const { readOnly } = useReadOnlyMode();

  return (
    <div className={readOnly ? 'nodrag' : ''} style={{ cursor: readOnly ? 'pointer' : 'initial' }}>
      <Flex
        justifyContent="center"
        alignItems="center"
        flexDir="column"
        width={`${NODE_WIDTH - 10}px`}
        height={`${NODE_HEIGHT - 10}px`}
        pointerEvents="none"
      >
        <Flex alignItems="center" flexDir="column">
          <Spinner />
          <Label
            mt={1}
            minWidth="100px"
            fontWeight={600}
            fontSize={10}
            lineHeight="1.2"
            textAlign="center"
          >
            creating node
          </Label>
        </Flex>
      </Flex>
    </div>
  );
}

export default memo(LoadingNode);
