import { useCallback } from 'react';

import useStore from 'views/Graph/state';

import useGraph from './api/useGraph';

function useGetUniqueNodeName() {
  const { data: graph } = useGraph({
    select: (graph) => ({ graph_files: graph.graph_files }),
  });

  return useCallback(
    (nodeNamePrefix: string, nodeNameSuffix: string = '') => {
      const { nodes } = useStore.getState();
      let nodeName = null;
      let index = 0;

      nodeNamePrefix = nodeNamePrefix.replace(' ', '_').replace(/[^a-zA-Z0-9_]/g, '');

      while (nodeName === null) {
        const nextName = index === 0 ? nodeNamePrefix : `${nodeNamePrefix}${index}`;
        index++;
        const nextFilePath = nextName + `${nodeNameSuffix}`;
        // next to check name and filepath are both available (otherwise for example when adding a python node
        // you can get 2 nodes with the same filepath which can't happen)
        const nameExists = nodes.find(
          (node) => node.data.name === nextName || node.data.filePath === nextFilePath
        );
        const fileExists = Object.keys(graph?.graph_files || {}).find(
          (fileName) => fileName === nextFilePath
        );

        if (!nameExists && !fileExists) {
          nodeName = nextName;
        }
      }

      return nodeName;
    },
    [graph?.graph_files]
  );
}

export default useGetUniqueNodeName;
