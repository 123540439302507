import React, {
  CSSProperties,
  ReactElement,
  ReactNode,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Button, Flex } from '@chakra-ui/react';

interface ShowHideProps extends React.HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  childRef: RefObject<HTMLDivElement>;
  maxHeight: number;
  appear?: boolean;
  show?: boolean;
}

const duration = 200;

const mask = 'linear-gradient(black, 90%, transparent)';

const defaultStyles: CSSProperties = {
  overflow: 'hidden',
  WebkitMaskImage: mask,
  maskImage: mask,
  transition: `all ${duration}ms ease`,
};

export default function ShowHide({
  maxHeight,
  children,
  childRef,
  ...rest
}: ShowHideProps): ReactElement {
  const [height, setHeight] = useState<number>();
  const [show, setShow] = useState<boolean>(false);
  const [disable, setDisable] = useState<boolean>(false);
  const nodeRef = React.useRef(null);

  useEffect(() => {
    if (childRef.current) {
      const childHeight = childRef.current.clientHeight;
      if (childHeight <= maxHeight) {
        setShow(true);
        setDisable(true);
      }
      setHeight(childRef.current.clientHeight);
    }
  }, [childRef, maxHeight]);

  function getStyle(status: TransitionStatus) {
    switch (status) {
      case 'entering':
        return {
          maxHeight: height,
        };
      case 'entered':
        return {
          maxHeight: height,
          maskImage: 'none',
          WebkitMaskImage: 'none',
        };
      case 'exiting':
        return {
          maxHeight: maxHeight,
        };
      case 'exited':
        return {
          maxHeight: maxHeight,
        };
    }
  }

  return (
    <>
      <Transition in={show} timeout={duration} nodeRef={nodeRef}>
        {(state) => (
          <div
            style={{
              ...defaultStyles,
              ...getStyle(state),
            }}
            ref={nodeRef}
            {...rest}
          >
            {children}
          </div>
        )}
      </Transition>
      {!disable && (
        <Flex justifyContent="center" mt={3}>
          <Button
            leftIcon={show ? <FiChevronUp /> : <FiChevronDown />}
            onClick={() => setShow(!show)}
            size="sm"
            variant="link"
          >
            {show ? 'Show less' : 'Show more'}
          </Button>
        </Flex>
      )}
    </>
  );
}
