import { UseMutationOptions } from '@tanstack/react-query';

import useGraph from 'hooks/api/useGraph';
import useGetUniqueNodeName from 'hooks/useGetUniqueNodeName';
import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { AddStoreNodeData } from 'types/index';
import { useAddStoreNodeURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

function useAddStoreNode(options?: UseMutationOptions<Graph, unknown, AddStoreNodeData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const getUniqueNodeName = useGetUniqueNodeName();
  const url = useAddStoreNodeURL(graphVersionUID);

  const result = useGraphEditMutate<Graph, unknown, AddStoreNodeData>(
    async ({
      templateType,
      parentId,
      position,
      nodeId,
      nodePort,
      direction,
      uniqueNodeName,
    }: AddStoreNodeData): Promise<Graph> => {
      const nodeName = uniqueNodeName || getUniqueNodeName(nodePort ? nodePort : templateType);

      const res = await Axios.post<Graph>(url, {
        name: nodeName,
        type: templateType,
        parent_id: parentId ? parentId : null,
        position_x: position.x,
        position_y: position.y,
        function_node_id: nodeId,
        function_node_port: nodePort,
        function_node_direction: direction,
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useAddStoreNode;
