import { FileOrFolder } from 'hooks/useFileSystem';

export function getFileName(path: string) {
  const fileParts = path.split('/').filter((part) => part.length > 0);
  return fileParts[fileParts.length - 1];
}

export function getPathPrefix(filePath: string) {
  const fileParts = filePath.split('/');
  const pathPrefix = fileParts.slice(0, fileParts.length - 1).join('/');
  return pathPrefix.length > 0 ? `${pathPrefix}/` : '';
}

export function getChildFiles(filePath: string, files: FileOrFolder[]): FileOrFolder[] {
  return files.filter((childFile) => {
    const pathPrefix = getPathPrefix(childFile.path);
    return pathPrefix === `${filePath}/`;
  });
}
