import React, { ReactNode, useCallback, useMemo } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { FiPlus } from 'react-icons/fi';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Placement,
  Portal,
  Spacer,
} from '@chakra-ui/react';

import useAddStoreNode from 'hooks/api/useAddStoreNode';
import useAssignPort from 'hooks/api/useAssignPort';
import useUpdateNodes from 'hooks/api/useUpdateNodes';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { GraphNode, PortType } from 'types';
import { isChartNodeType, isWebhookNodeType } from 'utils/nodes';
import { streamStoreTemplate, tableStoreTemplate } from 'utils/templates';
import useStore from 'views/Graph/state';

import { findSelectedStore, SelectStoreMenuItem } from '.';

export function generateRandomId() {
  return Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2);
}

type SelectStoreMenuProps = {
  node: GraphNode;
  portName: string;
  direction: 'output' | 'input';
  portType: PortType;
  buttonProps: MenuButtonProps;
  children: ReactNode;
  placement?: Placement;
};

const SelectStoreMenu = ({
  node,
  portName,
  direction,
  portType,
  children,
  buttonProps,
  placement,
}: SelectStoreMenuProps) => {
  const { store: selectedStore, isImplicit } = findSelectedStore(node.data, portName, direction);
  const { mutate: assignPort, isLoading: isAssigningPort, isGraphSaving } = useAssignPort();
  const { mutate: updateNodes, isLoading: isUpdatingNodes } = useUpdateNodes();

  const stores = useMemo(() => {
    const { nodes } = useStore.getState();
    return nodes.filter((n) => n.data.type === `${portType}_store`);
  }, [portType]);

  const { mutate: addStoreNode, isLoading: isAddingStoreNode } = useAddStoreNode();
  const { readOnly } = useReadOnlyMode();

  const isChartNode = isChartNodeType(node.data.type);
  const isWebhookNode = isWebhookNodeType(node.data.type);

  const handleSelectStore = useCallback(
    (targetStore: string | null) => {
      if (!node?.id) {
        return;
      }
      if (isChartNode) {
        const store = stores.find((n) => n.data.id === targetStore);
        updateNodes({ [node.id]: { chart_input: store?.data.name || generateRandomId() } });
      } else if (isWebhookNode) {
        const store = stores.find((n) => n.data.id === targetStore);
        updateNodes({ [node.id]: { webhook: store?.data.name || generateRandomId() } });
      } else {
        assignPort({
          function_node_id: node.id,
          store_node_id: targetStore,
          port_name: portName,
          direction,
        });
      }
    },
    [assignPort, direction, isChartNode, isWebhookNode, node.id, portName, stores, updateNodes]
  );

  const isLoading = isAssigningPort || isAddingStoreNode || isUpdatingNodes;

  const createStore = async (portType: PortType) => {
    const nodePort = isChartNode || isWebhookNode ? generateRandomId() : portName;

    const position = {
      x: Math.floor(node.position.x / 100) + (direction === 'input' ? -1 : 1),
      y: Math.floor(node.position.y / 100),
    };

    addStoreNode({
      templateType:
        portType === PortType.Stream
          ? streamStoreTemplate.templateType
          : tableStoreTemplate.templateType,
      position: position,
      nodeId: node.id,
      nodePort: nodePort,
      direction,
    });
  };

  const bgSelectedItem = 'bg3';
  const isEditable = !readOnly && (!node?.data.fromComponent || !node?.data.parentGraphLevel);

  return (
    <Menu size={'sm'} placement={placement || 'bottom'}>
      <MenuButton disabled={!isEditable || isGraphSaving || isLoading} w={'100%'} {...buttonProps}>
        {children}
      </MenuButton>
      <Portal>
        <MenuList fontSize={'sm'} maxHeight="400px" zIndex={11}>
          {!isImplicit && (
            <>
              <MenuItem
                bg={!selectedStore ? bgSelectedItem : 'transparent'}
                onClick={() => handleSelectStore(null)}
                icon={<CloseIcon w={'6px'} ml={'3px'} mr={'1px'} />}
                fontSize={'xs'}
              >
                <Flex alignItems={'center'}>
                  {!selectedStore ? 'disconnected' : 'disconnect'}
                  {!selectedStore && (
                    <>
                      <Spacer />
                      <BsCheck2 />
                    </>
                  )}
                </Flex>
              </MenuItem>
              <MenuDivider />
            </>
          )}
          <MenuGroup>
            <Box sx={{ overflowY: 'auto' }} maxH={'220px'}>
              {stores.length === 0 && (
                <MenuItem fontSize={'xs'} isDisabled={true}>
                  (no {portType === 'table' ? 'Table' : 'Stream'} stores defined in graph)
                </MenuItem>
              )}
              {stores
                .sort((a, b) => (a.data.name > b.data.name ? 1 : -1))
                .map((store) => (
                  <SelectStoreMenuItem
                    key={store.data.id}
                    selectedStore={selectedStore}
                    store={store}
                    isImplicit={isImplicit}
                    onSelect={(storeId) => handleSelectStore(storeId)}
                  />
                ))}
            </Box>
          </MenuGroup>
          {!isChartNode && (
            <>
              <MenuDivider />
              <MenuItem icon={<FiPlus />} onClick={() => createStore(portType)} fontSize={'xs'}>
                create {portType === 'table' ? 'Table' : 'Stream'} Store
              </MenuItem>
            </>
          )}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default SelectStoreMenu;
