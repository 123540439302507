import { ShapeProps } from './common';
import CustomPath from './CustomPath';

function Triangle({ width = 50, height = 62, ...rest }: ShapeProps) {
  const borderRadius = 5;
  const angle1 = Math.atan(width / (height / 2));
  const angle2 = Math.PI / 2 - angle1;

  const widthOffset = 5 * Math.sin(angle1);
  // This is sets the width of the triangle so that the rounded corner is actually the width we want
  const realWidth = width + widthOffset;

  const length1 = borderRadius / Math.tan(angle1 / 2);
  const x1 = length1 * Math.sin(angle1);
  const y1 = length1 * Math.cos(angle1);

  const length2 = borderRadius / Math.tan(angle2);
  const x2 = length2 * Math.cos(angle2);
  const y2 = length2 * Math.sin(angle2);

  // M = start, 0 0 => x y
  // L = line, 0 0 => x y
  // A = arc
  // Z = end
  const pathData = `
    M 0 ${length1}
    L 0 ${height - length1}
    A ${borderRadius} ${borderRadius} 0 0 0 ${x1} ${height - y1}
    L ${realWidth - x2} ${height / 2 + y2}
    A ${borderRadius} ${borderRadius} 0 0 0 ${realWidth - x2} ${height / 2 - y2}
    L ${x1} ${y1}
    A ${borderRadius} ${borderRadius} 0 0 0 0 ${length1}
    Z
  `;

  return <CustomPath {...{ pathData, ...rest }} />;
}

export default Triangle;
