import { CSSProperties, MouseEvent, ReactElement, useContext } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import shallow from 'zustand/shallow';

import { FileContext } from 'contexts/FileContext';
import useDeleteFile from 'hooks/api/useDeleteFile';
import { FileOrFolder } from 'hooks/useFileSystem';
import { GraphState } from 'types';
import useStore from 'views/Graph/state';

type FileNodeProps = {
  file: FileOrFolder;
  hovered: boolean;
  isPreview?: boolean;
};

const fileControlsStyle: CSSProperties = {
  position: 'absolute',
};

const selector = (s: GraphState) => ({
  readOnly: s.readOnly,
  removeFromWindow: s.removeFromWindow,
});

const FileNode = ({ file, hovered, isPreview }: FileNodeProps): ReactElement => {
  const { filesDispatcher, fileState } = useContext(FileContext);
  const { mutate: deleteFileMutate } = useDeleteFile();
  const { readOnly, removeFromWindow } = useStore(selector, shallow);

  const handleEdit = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (!fileState) return;
    filesDispatcher({
      type: 'SET',
      payload: {
        ...fileState,
        files: {
          ...fileState.files,
          [file.id]: {
            ...file,
            editMode: true,
          },
        },
      },
    });
  };

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    deleteFileMutate({ filePath: file.path });
    if (file?.nodeId) {
      removeFromWindow(null, file.nodeId);
    } else if (file?.path) {
      removeFromWindow(null, file.path);
    }
  };

  const indentions = file.path.split('/').length;

  return (
    <Flex data-testid="sidebar-filetree-file" justifyContent="space-between">
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        width={hovered && isPreview ? '100%' : 'calc(100% - 52px)'}
        // Hardcoded value for CodePreview
        maxWidth={isPreview ? '200px' : '100%'}
      >
        {file.fileName}
      </Box>
      {!file.readOnly && !isPreview && (
        <Flex
          className="sidebar-filetree-file-controls"
          style={
            hovered
              ? { ...fileControlsStyle, right: indentions * 16 }
              : { ...fileControlsStyle, display: 'none' }
          }
        >
          {!readOnly && (
            <>
              <IconButton
                colorScheme="gray"
                icon={<FiEdit />}
                aria-label="edit file"
                variant="ghost"
                onClick={handleEdit}
                disabled={file.fileName === 'graph.yml'}
                className="nodrag"
              />
              <IconButton
                colorScheme="gray"
                icon={<FiTrash />}
                aria-label="delete file"
                variant="ghost"
                onClick={handleDelete}
                disabled={file.fileName === 'graph.yml'}
                className="nodrag"
              />
            </>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default FileNode;
