import {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { colors } from 'styles/colors';

import { FileContext } from 'contexts/FileContext';
import useAddFile from 'hooks/api/useAddFile';
import useMovefile from 'hooks/api/useMoveFile';
import { FileOrFolder } from 'hooks/useFileSystem';
import useFocusLost from 'hooks/useFocusLost';

import { getPathPrefix } from './utils';

const inputWidth = 150;

const inputStyles: CSSProperties = {
  outline: 'none',
  fontSize: 12,
  width: inputWidth,
};

const errorMessageStyle: CSSProperties = {
  position: 'absolute',
  backgroundColor: 'white',
  fontSize: 10,
  border: `1px solid ${colors.dark.nodeError}`,
  width: inputWidth,
  color: colors.dark.nodeError,
  zIndex: 1000,
  paddingLeft: 5,
};

type FileFolderInputProps = {
  file: FileOrFolder;
};

const FileFolderInput = ({ file }: FileFolderInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fileState, filesDispatcher } = useContext(FileContext);
  const [fileName, setFileName] = useState(file.fileName);
  const [errorMessage, setErrorMessage] = useState('');
  const { mutate: addFileMutate } = useAddFile();
  const { mutate: moveFileMutate } = useMovefile();

  const cancel = () => {
    if (file.path.length === 0) {
      filesDispatcher({
        type: 'REMOVE',
        payload: 'tmp',
      });
    } else if (fileState) {
      filesDispatcher({
        type: 'SET',
        payload: {
          ...fileState,
          files: {
            ...fileState.files,
            [file.id]: {
              ...file,
              editMode: false,
            },
          },
        },
      });
    }
  };

  useFocusLost(cancel, [inputRef]);

  useEffect(() => {
    if (inputRef) {
      inputRef.current?.focus();
      const parts = file.fileName.split('.');
      // Sets the selection cursors around the file name
      inputRef.current?.setSelectionRange(0, parts[0].length);
    }
  }, [file.fileName]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (errorMessage.length > 0) {
      setErrorMessage('');
    }
    setFileName(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!fileState) return;
    if (event.key === 'Enter') {
      const pathPrefix = getPathPrefix(file.path);
      const newFilePath = `${pathPrefix}${fileName}`;
      // Handle file name already exists
      if (fileState?.hasOwnProperty(newFilePath) && newFilePath !== file.path) {
        setErrorMessage('File name already exists');
        return;
        // Valid file name
      } else if (!fileName.match(/^[\w\-. ]+$/g)) {
        setErrorMessage('File name is invalid');
        return;
      }

      // If file doesn't exist yet
      if (file.path.length === 0) {
        filesDispatcher({
          type: 'REMOVE',
          payload: 'tmp',
        });
      }

      // New file name is not empty
      if (fileName.length > 0) {
        // If new file
        if (file.fileName.length === 0) {
          addFileMutate({
            destination: newFilePath,
            file_type: file.type,
            fileContent: '',
          });
        } else {
          moveFileMutate({
            source: file.path,
            destination: newFilePath,
          });
        }
        filesDispatcher({
          type: 'SET',
          payload: {
            ...fileState,
            selectedFile: newFilePath,
          },
        });
        if (file.type === 'file') {
          // setGraphViewParams({ nodeid: null });
        }
      }
    } else if (event.key === 'Escape') {
      cancel();
    }
  };

  return (
    <>
      <input
        style={
          errorMessage
            ? { ...inputStyles, backgroundColor: `${colors.light.nodeError}` }
            : inputStyles
        }
        ref={inputRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={fileName}
        autoCapitalize="false"
        autoCorrect="false"
        autoComplete="false"
        spellCheck="false"
      />
      {errorMessage && <div style={errorMessageStyle}>{errorMessage}</div>}
    </>
  );
};

export default FileFolderInput;
