import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { Handle, Position, useStoreApi as useRFStore } from 'reactflow';
import { Flex, Menu, MenuButton, MenuGroup, MenuList, Portal } from '@chakra-ui/react';
import { colors } from 'styles/colors';
import shallow from 'zustand/shallow';

import config from 'config';
import useGraph from 'hooks/api/useGraph';
import useReadOnlyMode from 'hooks/useReadOnlyMode';
import { GraphNode, GraphTemplate } from 'types';
import { NodeType } from 'types/api';
import {
  isChartNodeType,
  isPythonNodeType,
  isSQLNodeType,
  isStoreNodeType,
  isStreamNodeType,
  isTableNodeType,
  isWebhookNodeType,
} from 'utils/nodes';
import {
  chartTemplate,
  simplePythonTemplate,
  sqlTemplate,
  tableStoreTemplate,
  TemplateType,
  webhookResponseTemplate,
  webhookStandardTemplate,
} from 'utils/templates';
import MenuItemUI from 'views/Graph/components/AddNodeMenu/MenuItemUI';
import useStore from 'views/Graph/state';

import useAddContextHandler from './useAddContextHandler';

const isValidType = (nodeType: NodeType) =>
  [
    isPythonNodeType,
    isTableNodeType,
    isStreamNodeType,
    isSQLNodeType,
    isWebhookNodeType,
    isChartNodeType,
  ].some((fn) => fn(nodeType));

type PatternsHandleProps = {
  node: GraphNode;
  side: 'left' | 'right';
  isConnectable: boolean;
  offset: number;
  diameter: number;
};

function PatternsHandle({ node, side, isConnectable, offset, diameter }: PatternsHandleProps) {
  const nodeData = node.data;
  const { data: graphUID } = useGraph({
    select: (graph) => graph.uid,
  });
  const { isHovering, connectingHandle, setHoverNodeId } = useStore(
    useCallback(
      (state) => {
        return {
          isHovering: state.hoverMenuNodeId === node.id,
          connectingHandle: state.connectingHandle,
          setHoverNodeId: state.setHoverNodeId,
        };
      },

      [node.id]
    ),
    shallow
  );

  const { readOnly } = useReadOnlyMode();

  const position = useMemo(() => {
    return {
      x: Math.floor(node.position.x / config.graphGrid.x) + (side === 'left' ? -1 : 1),
      y: Math.floor(node.position.y / config.graphGrid.y),
    };
  }, [node.position.x, node.position.y, side]);

  const {
    addChartToTable,
    addWebhookToTable,
    addTableToPython,
    addTableToSql,
    addTableToWebhook,
    addTableToChart,
    addPythonToTable,
    addSqlToTable,
  } = useAddContextHandler({ node, position, side });

  const [menuIsVisible, setMenuIsVisible] = useState(false);

  const onClick = useCallback(
    async (template: GraphTemplate) => {
      if (!graphUID) {
        return;
      }

      if (template.templateType === TemplateType.TABLE_STORE) {
        if (isPythonNodeType(nodeData.type)) {
          addTableToPython(template);
        } else if (isSQLNodeType(nodeData.type)) {
          addTableToSql(template);
        } else if (isWebhookNodeType(nodeData.type)) {
          addTableToWebhook(template);
        } else if (isChartNodeType(nodeData.type)) {
          addTableToChart(template);
        }
      } else if (template.templateType === TemplateType.CHART) {
        addChartToTable(template);
      } else if (template.templateType === TemplateType.WEBHOOK) {
        addWebhookToTable(template);
      } else if (template.templateType === TemplateType.SQL) {
        addSqlToTable(template);
      } else if (template.templateType === TemplateType.PYTHON) {
        addPythonToTable(template);
      }

      setHoverNodeId(null);
      setMenuIsVisible(false);
    },
    [
      graphUID,
      setHoverNodeId,
      nodeData.type,
      addTableToPython,
      addTableToSql,
      addTableToWebhook,
      addTableToChart,
      addChartToTable,
      addWebhookToTable,
      addSqlToTable,
      addPythonToTable,
    ]
  );

  useEffect(() => {
    if (menuIsVisible) {
      const reactFlowWrapper = document.querySelector('.react-flow__pane') as HTMLElement;
      reactFlowWrapper.addEventListener('mousedown', () => {
        setMenuIsVisible(false);
      });

      return () => {
        reactFlowWrapper.removeEventListener('mousedown', () => {
          setMenuIsVisible(false);
        });
      };
    }
  }, [setMenuIsVisible, menuIsVisible]);

  const { getState } = useRFStore();

  // Handles a fringe case where the node is selected but the user is not hovering it
  const clampedScaleRef = useRef(1);

  const clampedScale = useMemo(() => {
    // This prevents unnecessary renders when the user is not hovering the node
    if (!isHovering) return clampedScaleRef.current;
    // we need to reverse the scale so that the menu is not scaled
    const { transform } = getState();
    const scale = +(1 / transform[2]).toFixed(2);
    const clampedScale = Math.min(0.5 + scale / 2, 2);
    clampedScaleRef.current = clampedScale;

    return clampedScale;
  }, [getState, isHovering]);

  const tableMenuItem = useMemo(() => {
    return (
      <MenuItemUI
        name={tableStoreTemplate.name}
        onClick={() => onClick(tableStoreTemplate)}
        Icon={tableStoreTemplate.icon}
        disableDefaultClick
        label="Add Table"
      />
    );
  }, [onClick]);
  const pythonMenuItem = useMemo(() => {
    return (
      <MenuItemUI
        name={simplePythonTemplate.name}
        onClick={() => onClick(simplePythonTemplate)}
        Icon={simplePythonTemplate.icon}
        disableDefaultClick
        label="Add Python"
      />
    );
  }, [onClick]);
  const sqlMenuItem = useMemo(() => {
    return (
      <MenuItemUI
        name={sqlTemplate.name}
        onClick={() => onClick(sqlTemplate)}
        Icon={sqlTemplate.icon}
        disableDefaultClick
        label="Add SQL"
      />
    );
  }, [onClick]);
  const chartMenuItem = useMemo(() => {
    return (
      <MenuItemUI
        name={chartTemplate.name}
        onClick={() => onClick(chartTemplate)}
        Icon={chartTemplate.icon}
        disableDefaultClick
        label="Add Chart"
      />
    );
  }, [onClick]);
  const webhookMenuItem = useMemo(() => {
    return (
      <>
        <MenuItemUI
          name={webhookStandardTemplate.name}
          onClick={() => onClick(webhookStandardTemplate)}
          Icon={webhookStandardTemplate.icon}
          disableDefaultClick
          label="Add Webhook"
        />
        <MenuItemUI
          name={webhookResponseTemplate.name}
          onClick={() => onClick(webhookResponseTemplate)}
          Icon={webhookResponseTemplate.icon}
          disableDefaultClick
          label="Add Response Webhook"
        />
      </>
    );
  }, [onClick]);

  const menuItems = useMemo(() => {
    if (
      isPythonNodeType(nodeData.type) ||
      isSQLNodeType(nodeData.type) ||
      isWebhookNodeType(nodeData.type) ||
      isChartNodeType(nodeData.type)
    ) {
      return [tableMenuItem];
    } else if (side === 'left') {
      return [pythonMenuItem, sqlMenuItem, webhookMenuItem];
    } else if (side === 'right') {
      return [pythonMenuItem, sqlMenuItem, chartMenuItem];
    }
  }, [
    chartMenuItem,
    nodeData.type,
    pythonMenuItem,
    side,
    sqlMenuItem,
    tableMenuItem,
    webhookMenuItem,
  ]);

  const disabled = useMemo(
    () => readOnly || !isValidType(nodeData.type),
    [nodeData.type, readOnly]
  );

  const isStoreNode = isStoreNodeType(node.data.type);

  const handleStyle = useMemo(() => {
    const handleStyle: CSSProperties = {
      borderRadius: diameter / 2,
      width: diameter,
      height: diameter,
      zIndex: 10,
      border: 'none',
      boxShadow: `0 0 0 1px white`,
      backgroundColor: colors.gray[500],
    };

    if (side === 'left') {
      handleStyle.left = offset;
    } else {
      handleStyle.right = offset;
    }

    const validHandleStyle: CSSProperties = { ...handleStyle, backgroundColor: colors.green[500] };
    const invalidHandleStyle: CSSProperties = { ...handleStyle, opacity: 0, pointerEvents: 'none' };

    if (connectingHandle || isHovering) {
      if (connectingHandle) {
        if (node?.id === connectingHandle.nodeData.id) {
          return side === 'left'
            ? connectingHandle.type === 'source'
              ? invalidHandleStyle
              : handleStyle
            : connectingHandle.type === 'target'
            ? invalidHandleStyle
            : handleStyle;
        } else if (isStoreNodeType(connectingHandle.nodeData.type) == isStoreNode) {
          return invalidHandleStyle;
        } else {
          return side === 'left'
            ? connectingHandle.type === 'target'
              ? invalidHandleStyle
              : validHandleStyle
            : connectingHandle.type === 'source'
            ? invalidHandleStyle
            : validHandleStyle;
        }
      } else {
        return isHovering ? handleStyle : invalidHandleStyle;
      }
    } else {
      return invalidHandleStyle;
    }
  }, [diameter, side, connectingHandle, isHovering, offset, node?.id, isStoreNode]);

  return (
    <Handle
      type={side === 'left' ? 'target' : 'source'}
      style={{ opacity: disabled ? 0 : 1, ...handleStyle }}
      position={side === 'left' ? Position.Left : Position.Right}
      isConnectable={isConnectable && !disabled}
      onClick={(e) => {
        if (!disabled) {
          setMenuIsVisible(true);
        }
        e.stopPropagation();
      }}
    >
      {!disabled && (
        <Menu
          autoSelect={false}
          closeOnBlur
          onClose={() => {
            setMenuIsVisible(false);
          }}
          isOpen={menuIsVisible}
          placement={side === 'left' ? 'left-start' : 'right-start'}
          gutter={4}
        >
          <MenuButton pointerEvents="none" position="absolute" top={0} height="100%" width="100%">
            <FiPlus size="100%" color="white" />
          </MenuButton>
          <Flex position="absolute" zIndex={100} transform={`scale(${clampedScale})`}>
            <Portal>
              <MenuList fontSize="xs" color="text2" zIndex={100} minWidth="auto">
                <MenuGroup title="OPTIONS" color="text3" userSelect="none" mb={0} fontSize="xs">
                  {menuItems}
                </MenuGroup>
              </MenuList>
            </Portal>
          </Flex>
        </Menu>
      )}
    </Handle>
  );
}

export default PatternsHandle;
