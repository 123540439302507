import { UseMutationOptions } from '@tanstack/react-query';

import useGraph from 'hooks/api/useGraph';
import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useAddFileURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

type useAddFileData = {
  destination: string;
  file_type: 'file' | 'folder';
  fileContent?: string | null; // fileContent can be null/undefined if 'file' is a directory
};

function useAddFile(options?: UseMutationOptions<Graph, unknown, useAddFileData>) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const url = useAddFileURL(graphVersionUID);

  const result = useGraphEditMutate<Graph, unknown, useAddFileData>(
    async ({ destination, file_type, fileContent }: useAddFileData): Promise<Graph> => {
      const formData = new FormData();
      formData.append('destination', destination);
      formData.append('file_type', file_type);
      if (typeof fileContent === 'string') {
        const file = new File([fileContent], 'file', {
          type: 'text/plain',
        });
        formData.append('file', file);
      }

      const res = await Axios.post<Graph>(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (res.data.version_uid) {
        // Keeping track of the latest graph version updates for shitty multiplayer support
        const { setUpdatedGraphVersionUID } = useStore.getState();
        setUpdatedGraphVersionUID(res.data.version_uid);
      }

      return res.data;
    },
    options
  );

  return result;
}

export default useAddFile;
