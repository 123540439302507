import { UseMutationOptions } from '@tanstack/react-query';

import useGraphEditMutate from 'hooks/useGraphEditMutate';
import { Graph } from 'types/api';
import { useAssignPortURL } from 'utils/ApiEndpoints';
import Axios from 'utils/Axios';
import useStore from 'views/Graph/state';

import useGraph from './useGraph';

type UseAssignPort = {
  function_node_id: string;
  port_name: string;
  store_node_id: string | null; // null means unassigning a node
  direction: 'input' | 'output';
};

function useAssignPort(
  nodeId?: string,
  options?: UseMutationOptions<Graph, unknown, UseAssignPort>
) {
  const { data: graph } = useGraph({
    select: (graph) => ({ version_uid: graph.version_uid }),
  });
  const graphVersionUID = graph?.version_uid;
  const url = useAssignPortURL(graphVersionUID);

  return useGraphEditMutate<Graph, unknown, UseAssignPort>(async (args: UseAssignPort) => {
    const res = await Axios.post<Graph>(url, args);

    if (res.data.version_uid) {
      // Keeping track of the latest graph version updates for shitty multiplayer support
      const { setUpdatedGraphVersionUID } = useStore.getState();
      setUpdatedGraphVersionUID(res.data.version_uid);
    }

    return res?.data;
  }, options);
}

export default useAssignPort;
