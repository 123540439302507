import { GraphNode, NodeData } from 'types';
import { isChartNodeType, isWebhookNodeType } from 'utils/nodes';
import useStore from 'views/Graph/state';

export function findSelectedStore(
  nodeData: NodeData,
  portName: string,
  direction: 'input' | 'output'
): { store: GraphNode | undefined; isImplicit: boolean } {
  const { nodes } = useStore.getState();
  const stores = nodes.filter((n) => ['stream_store', 'table_store'].includes(n.data.type));
  if (isChartNodeType(nodeData.type) || isWebhookNodeType(nodeData.type)) {
    return {
      store: stores.find((n) => n.data.name === portName),
      isImplicit: false,
    };
  } else {
    const edges = direction === 'output' ? nodeData.local_output_edges : nodeData.local_input_edges;
    const edge = edges.find((e) => e.data.port === portName);
    const isImplicit = edge?.data.isImplicit;
    const storeId = direction === 'output' ? edge?.target : edge?.source;
    return {
      store: stores.find((n) => n.data.id === storeId),
      isImplicit,
    };
  }
}
