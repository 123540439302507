import { useCallback, useContext, useMemo, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Box } from '@chakra-ui/react';
import Tree from 'rc-tree';
import { Key } from 'rc-tree/lib/interface';
import { colors } from 'styles/colors';

import { Label } from 'components/Typography';
import { FileContext } from 'contexts/FileContext';
import 'rc-tree/assets/index.css';

import FileNodeAlertDialog from './FileNodeAlertDialog';
import { buildTree, IconWrapper, treeStyles } from './FilesView';

const darkTreeStyles = {
  ...treeStyles,
  '& .rc-tree .rc-tree-treenode .rc-tree-node-content-wrapper': {
    width: '100%',
    color: colors.dark.text3,
  },
  '& .rc-tree-treenode:hover': {
    backgroundColor: colors.dark.bg2,
  },
  '& .rc-tree-node-selected': {
    backgroundColor: colors.dark.bg4,
    boxShadow: 'none',
  },
};

function FilesViewPreview({ customSort }: { customSort?: (a: string, b: string) => number }) {
  const [hoveredNodeId, setHoveredNodeId] = useState<string>('');
  const [alertMessage, setAlertMessage] = useState<string>();
  const { fileState, filesDispatcher } = useContext(FileContext);

  const selectedKeys = useMemo(() => {
    return fileState?.selectedFile ? [fileState.selectedFile] : [];
  }, [fileState?.selectedFile]);

  const handleSelect = useCallback(
    (keys: Key[]) => {
      if (keys.length > 0 && keys.length === 1) {
        const file = fileState && fileState.files[keys[0].toString()];
        if (fileState) {
          filesDispatcher({
            type: 'SET',
            payload: {
              ...fileState,
              selectedFile: file?.id,
            },
          });
        }
      }
    },
    [fileState, filesDispatcher]
  );

  const treeData = useMemo(() => {
    if (fileState) {
      const sortedFiles = Object.keys(fileState.files)
        .sort(customSort)
        .map((key) => fileState.files[key]);
      return buildTree(sortedFiles, fileState.expandedFolders, hoveredNodeId, true);
    } else {
      return null;
    }
  }, [customSort, fileState, hoveredNodeId]);

  if (!fileState?.files || Object.keys(fileState?.files)?.length === 0) {
    return <Label px={2}>no data available</Label>;
  }

  return (
    <Box sx={darkTreeStyles} minHeight="100%">
      {treeData && (
        <Tree
          treeData={treeData}
          onSelect={handleSelect}
          selectedKeys={selectedKeys}
          onMouseEnter={(event) => setHoveredNodeId(event.node.key as string)}
          switcherIcon={(props) => {
            if (props.isLeaf) return <></>;
            return props.expanded ? (
              <IconWrapper>
                <FiChevronDown />
              </IconWrapper>
            ) : (
              <IconWrapper>
                <FiChevronUp />
              </IconWrapper>
            );
          }}
          expandedKeys={fileState?.expandedFolders}
          onExpand={(keys) => {
            if (!fileState) return;
            filesDispatcher({
              type: 'SET',
              payload: {
                ...fileState,
                expandedFolders: keys.map((key) => key.toString()),
              },
            });
          }}
        />
      )}
      {alertMessage && (
        <FileNodeAlertDialog message={alertMessage} onClose={() => setAlertMessage('')} />
      )}
    </Box>
  );
}

export default FilesViewPreview;
